import React, { useState } from "react";
import { Box, Center, Heading, Text, VStack, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

// Color theme constants
const COLORS = {
    isabelline: "#F4EEEA",    // Background, card backgrounds
    maastrichtBlue: "#0E1735", // Headings, important text
    shadowBlue: "#7481AF",    // Secondary elements, borders, labels
    mandarin: "#F17946",      // CTAs, highlights, errors
    shinyShamrock: "#56A27F"  // Success states, secondary actions
};

const TestLMS = () => {
    const navigate = useNavigate();
    const [isEnlarged, setIsEnlarged] = useState(false);

    const handleMonkeyClick = () => {
        setIsEnlarged(true);
        setTimeout(() => setIsEnlarged(false), 500); // Reset after 0.5 seconds
    };

    return (
        <Center minH="100vh" bg={COLORS.isabelline} p={8}>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                <VStack
                    spacing={8}
                    bg="white"
                    p={10}
                    borderRadius="lg"
                    boxShadow="xl"
                    maxW="600px"
                    textAlign="center"
                >
                    <Heading 
                        size="xl" 
                        color={COLORS.maastrichtBlue}
                        borderBottom="2px solid"
                        borderColor={COLORS.mandarin}
                        pb={2}
                    >
                        Whoops! 🚧
                    </Heading>

                    <Text fontSize="xl" color={COLORS.shadowBlue}>
                        Our Learning Management System is currently under construction!
                    </Text>

                    <Text color={COLORS.shadowBlue}>
                        Our team of highly trained monkeys are working hard to build something amazing for you.
                    </Text>

                    {/* Clickable monkey emoji */}
                    <motion.div
                        onClick={handleMonkeyClick}
                        style={{ cursor: "pointer" }}
                        animate={isEnlarged ? {
                            scale: [1, 3, 1], // Enlarge to 3x size and back
                            rotate: [0, 360] // Add a full rotation
                        } : {}}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                        whileHover={{ scale: 3 }} // Enlarge to 10x on hover
                    >
                        <Text fontSize="3rem">🐒</Text>
                    </motion.div>

                    <Button
                        onClick={() => navigate("/login")}
                        size="lg"
                        bg={COLORS.mandarin}
                        color="white"
                        _hover={{ bg: '#e06835' }}
                        _active={{ bg: '#d55a29' }}
                    >
                        Take Me Back Home
                    </Button>

                    <Text fontSize="sm" color={COLORS.shadowBlue} mt={4}>
                        Estimated Completion: When the coffee ☕ runs out!
                    </Text>
                </VStack>
            </motion.div>
        </Center>
    );
};

export default TestLMS;
