import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {supabase} from "./utils/supabaseClient";
import {Box, Button, Center, FormControl, FormLabel, Heading, Badge,Select, Stack, Text, HStack, Divider, Card,Link, useToast, Spinner, Container,VStack} from "@chakra-ui/react";
import {CheckCircleIcon} from "@chakra-ui/icons";
import { FiFile, FiLink, FiArrowRight, FiUpload } from "react-icons/fi";
import {saveAs} from "file-saver";
import {motion} from "framer-motion";
import {COLORS} from "./styles/colors";
import moment from "moment";

export const EachAssessment = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const toast = useToast()
    const participantName = "ABC"
    const {assessmentID} = useParams();
    const [selectedFile, setSelectedFile] = useState(null);
    const [courseMaterials, setCourseMaterials] = useState(null);
    const [loading, setLoading] = useState(true);

    const getAllCourseMaterials = async () => {
        try {
            const { data, error } = await supabase
                        .from("full_assessment_details")
                        .select("*")
                        .eq("assessment_id", assessmentID)
                        .single();

            if (error) throw error;
            setCourseMaterials(data);
        } catch (error) {
            console.error("Error fetching assessment details:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllCourseMaterials();
    }, []);

    useEffect(() => {
        const listFiles = async () => {
            if (courseMaterials?.code) {
                const { data, error } = await supabase.storage
                    .from("Course Files")
                    .list(courseMaterials.code);

                if (data) {
                    const filesWithPath = data.map(file => ({
                        name: file.name,
                        path: `${courseMaterials.code}/${file.name}`,
                    }));
                    console.log("Files in the folder with paths:", filesWithPath);
                } else {
                    console.error("Error listing files:", error);
                }
            }
        };
        listFiles();
    }, [courseMaterials?.code]);

    if (loading) {
        return (
            <Center minH="100vh" bg={COLORS.isabelline}>
                <Spinner size="xl" color={COLORS.mandarin} />
            </Center>
        );
    }

    if (!courseMaterials) {
        return (
            <Center minH="100vh" bg={COLORS.isabelline}>
                <Text color={COLORS.shadowBlue}>No assessment details found.</Text>
            </Center>
        );
    }

    // File download function
    // const downloadFile = async (filePath) => {
    //     try {
    //         const { data, error } = await supabase.storage.from("Assessment Files").download(filePath);
    //         if (error) throw error;
    //         const fileName = filePath.split("/").pop();
    //         saveAs(data, fileName);
    //     } catch (error) {
    //         console.error("Error downloading file:", error);
    //     }
    // };

    const downloadFile = async (filePath, bucketName="Course Files") => { 
        setIsDownloading(true)
        try {
            const { data, error } = await supabase.storage.from(bucketName).download(filePath);
            if (error) throw error;
    
            const fileName = filePath.split("/").pop();
            saveAs(data, fileName);
            
            // Insert a record into the "activity" table upon successful download
            const { error: activityError } = await supabase
            .from("activity")
            .insert({
                created_at: new Date().toISOString(),
                registration_id:courseMaterials.id,
                action: "Download",
                resource: fileName,
            });

        if (activityError) {
            console.error("Error logging download activity:", activityError);
        }
        } catch (error) {
            console.error(`Error downloading file from ${bucketName}:`, error);
        } finally {
            setIsDownloading(false);
        }
    };

    const handleFileUpload = async (file) => {
        setIsSubmitting(true)
        if (!file.name.endsWith(".ipynb")) {
            setIsSubmitting(false)
            toast({
                title: "Invalid file type",
                description: "Only .ipynb files are allowed.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const path = `${courseMaterials.id}/student/${file.name}`; //courseMaterials.name to be replaced by assignment name
        const { data, error } = await supabase.storage.from("Assessment Files").upload(path, file, {
            upsert: true,
        });

        if (error) {
            setIsSubmitting(false)
            toast({
                title: "Upload failed",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } else {
            // Insert a record into the "activity" table upon successful upload
            const { error: activityError } = await supabase
            .from("activity")
            .insert({
                created_at: new Date().toISOString(),
                registration_id:courseMaterials.id,
                action: "Upload",
                resource: file.name,
            });
            if (activityError) {
            console.error("Error logging upload activity:", activityError);
            }

            // Update the assessments table with `submitted_on` and `uploaded_file_name`
            const { data: updateData, error: updateError } = await supabase
            .from("assessments")
            .update({
                submitted_on: new Date().toISOString(), // Current timestamp
                uploaded_file_name: file.name, // File name
            })
            .eq("assessment_id", assessmentID);

            console.log("update response:", updateData, updateError);
            
            if (updateError) {
                setIsSubmitting(false)
                toast({
                    title: "Update failed",
                    description: updateError.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                setIsSubmitting(false)
                toast({
                    title: "Upload successful",
                    description: `Your assessment has been uploaded.`,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
            });
            getAllCourseMaterials();

             // Clear the file input
             setSelectedFile(null); // Clear state
             document.getElementById("file-upload").value = ""; // Clear file input field
            }
        }
    };

    return (
        <Box minH="100vh" bg={COLORS.isabelline} py={8}>
            <Container maxW="container.lg">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Box
                        bg="white"
                        p={8}
                        borderRadius="lg"
                        boxShadow="md"
                    >
                        <Heading
                            size="lg"
                            mb={6}
                            color={COLORS.maastrichtBlue}
                            borderBottom="2px solid"
                            borderColor={COLORS.mandarin}
                            pb={2}
                        >
                            Assessment for {courseMaterials.name}
                        </Heading>
                        <Text color={COLORS.shadowBlue} mb={6}>
                            {moment(courseMaterials.start_date).format("DD MMM YYYY")} - {moment(courseMaterials.end_date).format("DD MMM YYYY")}
                        </Text>

                        {courseMaterials?.assessment_items?.map((each, index) => (
                          <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: index * 0.1 }}
                          >
                            <Box mb={8}>
                              <HStack mb={2}>
                                <Heading size="md" color={COLORS.maastrichtBlue}>
                                  {each.assessment_name}
                                </Heading>
                                <Badge
                                  colorScheme={courseMaterials.submitted_on ? "green" : "red"}
                                >
                                  {courseMaterials.submitted_on ? "Submitted" : `Not Complete - Deadline: ${courseMaterials.assessment_deadline?.split("T")[0] || "No Deadline"}`}
                                </Badge>
                              </HStack>
                              <Divider mb={4} borderColor={COLORS.shadowBlue} />

                              {each?.items?.map((item, itemIndex) => {
                                if (item.type === "text") {
                                  return (
                                    <Text key={itemIndex} mb={5} color={COLORS.shadowBlue}>
                                      {item.item_name}
                                    </Text>
                                  );
                                } else if (item.type === "submission") {
                                  return (
                                    <Card
                                      key={itemIndex}
                                      p={5}
                                      borderRadius="md"
                                      borderWidth="1px"
                                      borderColor={COLORS.shadowBlue}
                                      mb={5}
                                    >
                                      <VStack align="stretch">
                                        <Text color={COLORS.maastrichtBlue}>{item.item_name}</Text>
                                        <Box mt={5}>
                                          <input
                                            id="file-upload"
                                            type="file"
                                            accept=".ipynb"
                                            onChange={(e) => setSelectedFile(e.target.files[0])}
                                            style={{ marginBottom: "10px" }}
                                          />
                                          <Button
                                            isLoading={isSubmitting}
                                            leftIcon={<FiUpload />}
                                            onClick={() => handleFileUpload(selectedFile)}
                                            isDisabled={!selectedFile}
                                            colorScheme="blue"
                                          >
                                            Upload
                                          </Button>
                                        </Box>
                                      </VStack>
                                    </Card>
                                  );
                                } else {
                                  return (
                                    <Card
                                      key={itemIndex}
                                      p={5}
                                      borderRadius="md"
                                      borderWidth="1px"
                                      borderColor={COLORS.shadowBlue}
                                      mb={5}
                                    >
                                      <HStack>
                                        <FiFile color={COLORS.mandarin} />
                                        <Link
                                          as="span"
                                          onClick={() => downloadFile(`${courseMaterials.code}/${item.item_name}`)}
                                          color={COLORS.maastrichtBlue}
                                          textDecoration="underline"
                                          cursor="pointer"
                                        >
                                          {item.item_name}
                                        </Link>
                                      </HStack>
                                    </Card>
                                  );
                                }
                              })}
                            </Box>
                          </motion.div>
                        ))}
                        {courseMaterials.submitted_on && (
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            <Card
                              p={5}
                              borderRadius="md"
                              borderWidth="1px"
                              borderColor={COLORS.shadowBlue}
                              bg="green.50"
                              mb={5}
                            >
                              <Heading size="sm" mb={2} color="green.700">
                                Your Submission
                              </Heading>
                              <Text mb={2} color={COLORS.maastrichtBlue}>
                                File Name: <strong>{courseMaterials.uploaded_file_name}</strong>
                              </Text>
                              <Button
                                isLoading={isDownloading}
                                leftIcon={<FiFile />}
                                colorScheme="blue"
                                onClick={() => downloadFile(`${courseMaterials.id}/student/${courseMaterials.uploaded_file_name}`,"Assessment Files")}
                              >
                                Download Your Submission
                              </Button>
                              <Text mt={2} fontSize="sm" color={COLORS.shadowBlue}>
                                Submitted on: {moment(courseMaterials.submitted_on).format("DD MMM YYYY, h:mm A")}
                              </Text>
                            </Card>
                          </motion.div>
                        )}
                    </Box>
                </motion.div>
            </Container>
        </Box>
    )
}
