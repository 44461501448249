import React, { useState, useEffect } from "react";
import {
    Input,
    Button,
    Stack,
    Text,
    FormControl,
    FormErrorMessage,
    useToast,
    CircularProgress,
    Flex,
    Center,
    Box,
    Image,
    Heading,
    Container,
    VStack,
    FormLabel
} from "@chakra-ui/react";
import { useAuth } from "./providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

// Color theme constants
const COLORS = {
    isabelline: "#F4EEEA",    // Background, card backgrounds
    maastrichtBlue: "#0E1735", // Headings, important text
    shadowBlue: "#7481AF",    // Secondary elements, borders, labels
    mandarin: "#F17946",      // CTAs, highlights, errors
    shinyShamrock: "#56A27F"  // Success states, secondary actions
};

const LoginPage = () => {
    const { login, auth } = useAuth();
    const [loginForm, setLoginForm] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    const validate = () => {
        let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
        setLoginError();
        setErrors({});
        if (loginForm.email === undefined || loginForm.email === "") {
            setErrors({ ...errors, email: "Email is required" });
            return false;
        } else if (!reg.test(loginForm.email)) {
            setErrors({ ...errors, email: "Must be a valid email address" });
            return false;
        }

        if (loginForm.password === undefined || loginForm.password === "") {
            setErrors({ ...errors, password: "Password is required" });
            return false;
        }
        return true;
    };

    const loginUser = async (email, password) => {
        try {
            setLoading(true);
            const { error } = await login(email, password);
            if (error) {
                setLoginError(error.message);
            } else {
                toast({
                    title: "Login Successful",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoginError(error.message);
        }
    };

    useEffect(() => {
        if (auth) {
            navigate("/enrolled-courses");
        }
    }, [auth]);

    const handleLogin = () => {
        if (validate()) {
            setLoginError("");
            setLoading(true);
            setTimeout(() => {
                loginUser(loginForm.email, loginForm.password);
                setLoading(false);
            }, 2000);
        }
    };

    // Add this function to handle Enter key press
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <Box 
            minH="100vh" 
            w="100%" 
            bg={COLORS.isabelline}
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={8}
            onKeyDown={handleKeyDown}
        >
            <Container maxW="container.md">
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                >
                    <Box 
                        bg="white" 
                        p={8} 
                        borderRadius="lg" 
                        boxShadow="md"
                        textAlign="center"
                    >
                        <VStack spacing={5} align="center">
                            <motion.div
                                initial={{ y: -50 }}
                                animate={{ y: 0 }}
                                transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
                            >
                                <Image
                                    src="../sozcode-logo-1.png"
                                    alt="Sozcode Logo"
                                    boxSize="200px"
                                    objectFit="contain"
                                    mb={0}
                                />
                            </motion.div>

                            <motion.div
                                initial={{ y: -50 }}
                                animate={{ y: 0 }}
                                transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
                            >
                                <Heading 
                                    size="md" 
                                    color={COLORS.maastrichtBlue}
                                    borderBottom="2px solid"
                                    borderColor={COLORS.mandarin}
                                    pb={2}
                                >
                                    Login to Your Account
                                </Heading>
                            </motion.div>

                            <FormControl
                                isRequired
                                isInvalid={"email" in errors || "password" in errors}
                            >
                                <VStack spacing={4} align="stretch">
                                    <Box>
                                        <FormLabel color={COLORS.shadowBlue} fontWeight="600">Email</FormLabel>
                                        <Input
                                            id="email"
                                            type="text"
                                            placeholder="Enter your email"
                                            value={loginForm.email}
                                            onChange={(e) =>
                                                setLoginForm({ ...loginForm, email: e.target.value })
                                            }
                                            bg="white"
                                            borderColor={COLORS.shadowBlue}
                                            _hover={{ borderColor: COLORS.maastrichtBlue }}
                                            _focus={{ borderColor: COLORS.mandarin, boxShadow: `0 0 0 1px ${COLORS.mandarin}` }}
                                        />
                                        {"email" in errors && (
                                            <FormErrorMessage fontSize="xs">{errors.email}</FormErrorMessage>
                                        )}
                                    </Box>

                                    <Box>
                                        <FormLabel color={COLORS.shadowBlue} fontWeight="600">Password</FormLabel>
                                        <Input
                                            id="password"
                                            type="password"
                                            placeholder="Enter your password"
                                            value={loginForm.password}
                                            onChange={(e) =>
                                                setLoginForm({ ...loginForm, password: e.target.value })
                                            }
                                            bg="white"
                                            borderColor={COLORS.shadowBlue}
                                            _hover={{ borderColor: COLORS.maastrichtBlue }}
                                            _focus={{ borderColor: COLORS.mandarin, boxShadow: `0 0 0 1px ${COLORS.mandarin}` }}
                                        />
                                        {"password" in errors && (
                                            <FormErrorMessage fontSize="xs">
                                                {errors.password}
                                            </FormErrorMessage>
                                        )}
                                    </Box>

                                    {loginError && (
                                        <Text color="red.500" fontSize="sm">
                                            {loginError}
                                        </Text>
                                    )}

                                    {isLoading ? (
                                        <Center>
                                            <CircularProgress 
                                                isIndeterminate 
                                                size="35px" 
                                                color={COLORS.mandarin}
                                            />
                                        </Center>
                                    ) : (
                                        <Button
                                            onClick={handleLogin}
                                            mt="4"
                                            width="full"
                                            height="40px"
                                            bg={COLORS.mandarin}
                                            color="white"
                                            _hover={{ bg: '#e06835' }}
                                            _active={{ bg: '#d55a29' }}
                                            isLoading={isLoading}
                                        >
                                            Login
                                        </Button>
                                    )}
                                </VStack>
                            </FormControl>
                        </VStack>
                    </Box>
                </motion.div>
            </Container>
        </Box>
    );
};

export default LoginPage;
