import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {supabase} from "./utils/supabaseClient";
import {Box, Button, Center, FormControl, FormLabel, Heading, Select, Stack, Text, HStack, Divider,Link, Card, Icon, Spinner,Container} from "@chakra-ui/react";
import {CheckCircleIcon} from "@chakra-ui/icons";
import { FiFile, FiLink, FiArrowRight } from "react-icons/fi";
import {saveAs} from "file-saver";
import {motion} from "framer-motion";
import {COLORS} from "./styles/colors";
import moment from "moment";


export const EachCourse = () => {
    const [courseMaterials, setCourseMaterials] = useState(null);
    const {courseRunId} = useParams();
    const getAllCourseMaterials = async () => {
        const {data, error } = await supabase
            .from("full_registration_details_with_enrollment")
            .select("*")
            .eq("id", courseRunId) 
            .single();

        if (data) {
            console.log(data);
            setCourseMaterials(data);
        }
        if (error) {
            console.error("Error fetching course materials:", error);
        }
    };

    const downloadFile = async (filePath) => {
        try {
            const { data, error } = await supabase.storage.from("Course Files").download(filePath);
            if (error) throw error;
            const fileName = filePath.split("/").pop();
            saveAs(data, fileName); // Use file-saver to trigger download
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    useEffect(() => {
        getAllCourseMaterials()
    }, [])

    useEffect(() => {
        const listFiles = async () => {
          const folderName = "SZCSSG01"; // Specify your folder name
          const { data, error } = await supabase.storage.from("Course Files").list(folderName);
      
          if (data) {
            const filesWithPath = data.map(file => ({
              name: file.name,
              path: `${folderName}/${file.name}`, // Construct the full file path
            }));
            console.log("Files in the folder with paths:", filesWithPath);
          } else {
            console.error("Error listing files:", error);
          }
        };
        listFiles();
      }, []);

    // const listBuckets = async () => {
    //     const { data, error } = await supabase.storage.listBuckets();
    //     if (error) {
    //         console.error('Error listing buckets:', error);
    //     } else {
    //         console.log('Available buckets:', data);
    //     }
    // };
    
    // listBuckets();



    if (!courseMaterials) {
        return (
            <Center minH="100vh" bg={COLORS.isabelline}>
                <Spinner size="xl" color={COLORS.mandarin} />
            </Center>
        );
    }
    
    if (!courseMaterials.course_resources) {
        return (
            <Center minH="100vh" bg={COLORS.isabelline}>
                <Text color={COLORS.shadowBlue}>No resources available for this course.</Text>
            </Center>
        );
    }
    return (
        <Box minH="100vh" bg={COLORS.isabelline} py={8}>
            <Container maxW="container.lg">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Box
                        bg="white"
                        p={8}
                        borderRadius="lg"
                        boxShadow="md"
                    >
                        <Heading
                            size="lg"
                            mb={6}
                            color={COLORS.maastrichtBlue}
                            borderBottom="2px solid"
                            borderColor={COLORS.mandarin}
                            pb={2}
                        >
                            Resources for {courseMaterials.course_resources.name}
                        </Heading>
                        <Text color={COLORS.shadowBlue} mb={6}>
                            {moment(courseMaterials.start_date).format("DD MMM YYYY")} - {moment(courseMaterials.end_date).format("DD MMM YYYY")}
                        </Text>

                        {courseMaterials?.course_resources.resources?.map((each, resourceIndex) => (
                            <motion.div
                                key={`resource-${resourceIndex}`}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3, delay: resourceIndex * 0.1 }}
                            >
                                <Box mb={8}>
                                    <HStack mb={2}>
                                        <Heading size="md" color={COLORS.maastrichtBlue}>
                                            {each.resource_name}
                                        </Heading>
                                    </HStack>
                                    <Divider mb={4} borderColor={COLORS.shadowBlue} />
                                    {each?.items?.map((item, itemIndex) => {
                                        if (item.type === "file") {
                                            return (
                                                <Card
                                                    key={`file-${resourceIndex}-${itemIndex}`}
                                                    p={4}
                                                    mb={4}
                                                    borderRadius="md"
                                                    borderWidth="1px"
                                                    borderColor={COLORS.shadowBlue}
                                                    _hover={{ shadow: "md" }}
                                                >
                                                    <HStack>
                                                        <Icon as={FiFile} color={COLORS.mandarin} />
                                                        <Link
                                                            as="span"
                                                            onClick={() => downloadFile(item.item_name)}
                                                            color={COLORS.maastrichtBlue}
                                                            textDecoration="underline"
                                                            cursor="pointer"
                                                        >
                                                            Download {item.item_name.split("/").pop()}
                                                        </Link>
                                                    </HStack>
                                                </Card>
                                            );
                                        } else if (item.type === "link") {
                                            return (
                                                <Card
                                                    key={`link-${resourceIndex}-${itemIndex}`}
                                                    p={4}
                                                    mb={4}
                                                    borderRadius="md"
                                                    borderWidth="1px"
                                                    borderColor={COLORS.shadowBlue}
                                                    _hover={{ shadow: "md" }}
                                                >
                                                    <HStack>
                                                        <Icon as={FiLink} color={COLORS.mandarin} />
                                                        <Link
                                                            href={item.item_name}
                                                            isExternal
                                                            color={COLORS.maastrichtBlue}
                                                            textDecoration="underline"
                                                        >
                                                            {item.item_name}
                                                        </Link>
                                                    </HStack>
                                                </Card>
                                            );
                                        } else {
                                            return (
                                                <Text
                                                    key={`text-${resourceIndex}-${itemIndex}`}
                                                    mb={4}
                                                    color={COLORS.shadowBlue}
                                                >
                                                    {item.item_name}
                                                </Text>
                                            );
                                        }
                                    })}
                                </Box>
                            </motion.div>
                        ))}
                    </Box>
                </motion.div>
            </Container>
        </Box>
    );
};