import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
  Box,
  Text,
  VStack,
  Table,
  Tbody,
  Tr,
  Td,
  Spinner,
  Button,
  Heading,
  Icon,
  Badge,
  Container,
  Center
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { CheckCircleIcon } from "@chakra-ui/icons";

// Add color theme constants
const COLORS = {
    isabelline: "#F4EEEA",    // Background, card backgrounds
    maastrichtBlue: "#0E1735", // Headings, important text
    shadowBlue: "#7481AF",    // Secondary elements, borders, labels
    mandarin: "#F17946",      // CTAs, highlights, errors
    shinyShamrock: "#56A27F"  // Success states, secondary actions
};

export const SuccessAcknowledgement = () => {
  const { assessmentID } = useParams();
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchAssessment = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from("full_assessment_details")
          .select(
            "assessment_id, assessment_name, participant_name, is_acknowledged, assessment_results, submitted_on, marked_on, remarks,name"
          )
          .eq("assessment_id", assessmentID)
          .single();

        if (error) throw error;

        setAssessmentData(data);
      } catch (error) {
        console.error("Error fetching assessment:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessment();
  }, [assessmentID]);

  const saveAcknowledgement = async () => {
    setSubmitting(true);
    try {
      const { error } = await supabase
        .from("assessments")
        .update({ is_acknowledged: true })
        .eq("assessment_id", assessmentID);

      if (error) throw error;

      setAssessmentData((prevData) => ({
        ...prevData,
        is_acknowledged: true,
      }));
    } catch (error) {
      console.error("Error updating assessment.");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Center h="100vh" bg={COLORS.isabelline}>
        <Spinner size="xl" thickness="4px" speed="0.65s" color={COLORS.mandarin} />
      </Center>
    );
  }

  return (
    <Container 
      w={"100%"} 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      bg={COLORS.isabelline} 
      minH="100vh" 
      py={8}
    >
      <Box 
        width="100%" 
        bg="white" 
        maxW={"600px"}
        px={6}
        py={8}
        borderRadius="lg" 
        boxShadow="md"
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <VStack spacing={4} align="center" mb={6}>
            <Heading size="lg" color={COLORS.maastrichtBlue}>
              {assessmentData?.is_acknowledged ? (
                <>
                  <Icon as={CheckCircleIcon} color={COLORS.shinyShamrock} boxSize={6} />
                  <Text as="span" ml={2}>
                    Results Acknowledged
                  </Text>
                </>
              ) : (
                "Assessment Results"
              )}
            </Heading>
            <Badge
              colorScheme={assessmentData?.is_acknowledged ? "green" : "blue"}
              fontSize="lg"
              px={4}
              py={1}
              borderRadius="full"
            >
              {assessmentData?.is_acknowledged
                ? "Acknowledged"
                : "Pending Acknowledgement"}
            </Badge>
          </VStack>

          <Table variant="simple" size="md">
            <Tbody>
              <Tr>
                <Td fontWeight="bold" color={COLORS.maastrichtBlue}>Participant Name</Td>
                <Td color={COLORS.shadowBlue}>{assessmentData?.participant_name || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" color={COLORS.maastrichtBlue}>Assessment Name</Td>
                <Td color={COLORS.shadowBlue}>{assessmentData?.assessment_name || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" color={COLORS.maastrichtBlue}>Results</Td>
                <Td color={COLORS.shadowBlue}>{assessmentData?.assessment_results || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" color={COLORS.maastrichtBlue}>Submitted On</Td>
                <Td color={COLORS.shadowBlue}>{assessmentData?.submitted_on?.split("T")[0] || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" color={COLORS.maastrichtBlue}>Marked On</Td>
                <Td color={COLORS.shadowBlue}>{assessmentData?.marked_on?.split("T")[0] || "N/A"}</Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold" color={COLORS.maastrichtBlue}>Remarks</Td>
                <Td color={COLORS.shadowBlue}>{assessmentData?.remarks || "N/A"}</Td>
              </Tr>
            </Tbody>
          </Table>

          <Box
            bg={COLORS.isabelline}
            p={4}
            borderRadius="md"
            mt={6}
            fontSize="md"
            textAlign="center"
            border="1px solid"
            borderColor={COLORS.shadowBlue}
          >
            {assessmentData?.is_acknowledged ? (
              <Text color={COLORS.maastrichtBlue}>
                I, <strong>{assessmentData?.participant_name}</strong>, acknowledged that I sat for my{" "}
                <strong>{assessmentData?.name}</strong> -{" "}
                <strong>{assessmentData?.assessment_name}</strong> on{" "}
                <strong>{assessmentData?.submitted_on?.split("T")[0]}</strong> and Sozcode has conveyed my assessment outcome to me.
              </Text>
            ) : (
              <Text color={COLORS.maastrichtBlue}>
                By clicking the button below, I, <strong>{assessmentData?.participant_name}</strong>, acknowledge that I sat for my{" "}
                <strong>{assessmentData?.name}</strong> -{" "}
                <strong>{assessmentData?.assessment_name}</strong> on{" "}
                <strong>{assessmentData?.submitted_on?.split("T")[0]}</strong> and Sozcode has conveyed my assessment outcome to me.
              </Text>
            )}
          </Box>

          <Button
            mt={6}
            w="full"
            bg={assessmentData?.is_acknowledged ? COLORS.shinyShamrock : COLORS.mandarin}
            color="white"
            _hover={{
              bg: assessmentData?.is_acknowledged ? '#4a8f6e' : '#e06835',
            }}
            _active={{
              bg: assessmentData?.is_acknowledged ? '#3d7a5b' : '#d55a29',
            }}
            isDisabled={assessmentData?.is_acknowledged || submitting}
            onClick={saveAcknowledgement}
          >
            {assessmentData?.is_acknowledged
              ? "Thank you!"
              : "Acknowledge Results"}
          </Button>
        </motion.div>
      </Box>
    </Container>
  );
};

export default SuccessAcknowledgement;