import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../utils/supabaseClient";

const AuthContext = createContext({
  user: null,
  auth: false,
  profile: null,
  isLoading: true, // Set initial loading state to true
  login: () => {},
  updateProfile: () => {},
  signOut: () => {},
  switchClass: () => {},
});

export const useAuth = () => useContext(AuthContext);

const login = async (email, password) => {
  return supabase.auth.signInWithPassword({ email, password });
};

const signOut = async () => {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    console.log("User signed out successfully");
  } catch (error) {
    console.error("Error signing out:", error.message);
  }
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      //console.log("Initial session:", data?.session);
      if (error) {
        console.error("Error fetching session:", error);
      }

      if (data?.session?.user) {
        setUser(data.session.user);
        setAuth(true);
      } else {
        setUser(null);
        setAuth(false);
      }
      setLoading(false); // Ensure loading is stopped after fetching session
    };

    fetchSession();

    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      //console.log("Auth state changed:", event, session);
      if (session?.user) {
        setUser(session.user);
        setAuth(true);
      } else {
        setUser(null);
        setAuth(false);
      }
      setLoading(false); // Ensure loading stops on auth state change
    });

    return () => {
      data.subscription?.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        auth,
        isLoading,
        login,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

// import { createContext, useContext, useEffect, useState } from "react";
// import { supabase } from "../utils/supabaseClient";

// const AuthContext = createContext({
//   user: null,
//   auth: false,
//   profile: null,
//   isLoading: true,  // Set initial loading state to true
//   login: () => {},
//   updateProfile: () => {},
//   signOut: () => {},
//   switchClass: () => {},
// });
// export const useAuth = () => useContext(AuthContext);

// const login = async (email, password) => {
//   return supabase.auth.signInWithPassword({ email, password });
// };

// const signOut = async () => {
//   try {
//     const { error } = await supabase.auth.signOut();
//     if (error) throw error;
//     console.log("User signed out successfully");
//   } catch (error) {
//     console.error("Error signing out:", error.message);
//   }
// };

// const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [auth, setAuth] = useState(false);
//   const [isLoading, setLoading] = useState(true);


//   useEffect(() => {
//     const timeoutId = setTimeout(() => {
//       if (isLoading) {
//         console.log("Authentication check timeout");
//         setAuth(false);
//         setLoading(false);
//       }
//     }, 10000); // Set timeout to 10 seconds


    
//     const { data } = supabase.auth.onAuthStateChange(async (event, session) => { // Make the callback async
//       clearTimeout(timeoutId); // Clear timeout if auth state changes
//       console.log("Auth state change event:",event,session);
//       if (session?.user) {
//         setUser(session.user);
//         setAuth(true);
//       } else {
//         setUser(null);
//         setAuth(false);
//       }
//       setLoading(false);
//     });

//     return () => {
//       clearTimeout(timeoutId);
//       data.subscription?.unsubscribe();
//     };
//   }, []);

//   return (
//       <AuthContext.Provider value={{
//         user,
//         auth,
//         isLoading,
//         login,
//         signOut,
//       }}>
//         {children}
//       </AuthContext.Provider>
//   );
// };

// export default AuthProvider;

