import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
  Box,
  Button,
  Heading,
  Card,
  HStack,
  Badge,
  Text,
  VStack,
  Container,
} from "@chakra-ui/react";
import { useAuth } from "./providers/AuthProvider";
import { motion } from "framer-motion";
import moment from "moment";

const COLORS = {
    isabelline: "#F4EEEA",    // Background, card backgrounds
    maastrichtBlue: "#0E1735", // Headings, important text
    shadowBlue: "#7481AF",    // Secondary elements, borders, labels
    mandarin: "#F17946",      // CTAs, highlights, errors
    shinyShamrock: "#56A27F"  // Success states, secondary actions
};

export const EnrolledCourses = () => {
  const {auth, isLoading} = useAuth();
  const {user} = useAuth();
  const [courses, setCourses] = useState([]);
  console.log("Enrolledcourses User",user.id)

  const getAllEnrolledCourses = async () => {
    try {
      const { data, error } = await supabase
        .from("full_registration_details_with_enrollment")
        .select(
          "id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality, course_dates, user_id"
        )
        .gte("end_date", new Date().toLocaleDateString("fr-CA"))
        .eq("user_id", user.id); 

      if (error) {
        console.error("Error fetching courses:", error);
        return;
      }

      // Sort courses by start_date
      const sortedCourses = data.sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date);
      });

      setCourses(sortedCourses || []); // Set courses or empty array
    } catch (error) {
      console.error("Unexpected error fetching courses:", error);
    }
  };

  useEffect(() => {
    getAllEnrolledCourses();
  }, []);

  return (
    <Box minH="100vh" bg={COLORS.isabelline} py={8}>
      <Container maxW="container.lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box
            bg="white"
            p={8}
            borderRadius="lg"
            boxShadow="md"
          >
            <Heading 
              size="lg" 
              mb={6} 
              color={COLORS.maastrichtBlue}
              borderBottom="2px solid"
              borderColor={COLORS.mandarin}
              pb={2}
            >
              Your Enrolled Courses
            </Heading>

            {courses.length > 0 ? (
              <VStack spacing={6} align="stretch">
                {courses.map((each) => (
                  <motion.div
                    key={each.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Card
                      p={6}
                      borderRadius="md"
                      borderWidth="1px"
                      borderColor={COLORS.shadowBlue}
                      _hover={{ shadow: "lg" }}
                    >
                      <Badge 
                        mb={4} 
                        colorScheme={
                          each.registration_stage === "confirmed" ? "green" : "blue"
                        }
                        alignSelf="flex-start"
                      >
                        {each.registration_stage || "In Progress"}
                      </Badge>

                      <Heading size="md" mb={4} color={COLORS.maastrichtBlue}>
                        {each.name}
                      </Heading>

                      <VStack spacing={3} align="stretch" mb={4}>
                        <HStack>
                          <Text w={150} color={COLORS.shadowBlue} fontWeight="500">
                            Start Date:
                          </Text>
                          <Text>{moment(each.start_date).format("DD MMM YYYY")}</Text>
                        </HStack>
                        <HStack>
                          <Text w={150} color={COLORS.shadowBlue} fontWeight="500">
                            End Date:
                          </Text>
                          <Text>{moment(each.end_date).format("DD MMM YYYY")}</Text>
                        </HStack>

                        {/* Scheduled Dates Section */}
                        <Box mt={3}>
                          <Text fontWeight="500" color={COLORS.shadowBlue} mb={2}>
                            Scheduled Dates:
                          </Text>
                          {each.course_dates && each.course_dates.length > 0 ? (
                            each.course_dates.map((session, index) => (
                              <HStack key={index} spacing={3} mb={2}>
                                <Text>Date: {moment(session.date, "DD.MM.YYYY").format("DD MMM YYYY")}</Text>
                                <Text>
                                  Time: {moment(session.start_time, "HHmm").format("h:mm A")} - {moment(session.end_time, "HHmm").format("h:mm A")}
                                </Text>
                              </HStack>
                            ))
                          ) : each.start_date === each.end_date ? (
                            // One-day course fallback
                            <HStack>
                              <Text>Date: {each.start_date}</Text>
                              <Text>Time: 0900-1700</Text>
                            </HStack>
                          ) : (
                            // Multi-day course fallback
                            <>
                              <HStack>
                                <Text>Date: {each.start_date}</Text>
                                <Text>Time: 0900-1700</Text>
                              </HStack>
                              <HStack>
                                <Text>Date: {each.end_date}</Text>
                                <Text>Time: 0900-1700</Text>
                              </HStack>
                            </>
                          )}
                        </Box>
                      </VStack>

                      <Link to={`/courses/${each.id}`}>
                        <Button
                          variant="solid"
                          bg={COLORS.mandarin}
                          color="white"
                          _hover={{ bg: '#e06835' }}
                          _active={{ bg: '#d55a29' }}
                        >
                          View Course Details
                        </Button>
                      </Link>
                    </Card>
                  </motion.div>
                ))}
              </VStack>
            ) : (
              <Text color={COLORS.shadowBlue} textAlign="center">
                You haven't enrolled in any courses yet.
              </Text>
            )}
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};