import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
  Box,
  Text,
  useToast,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Spinner,
  Image,
  Heading,
  Container,
  Center
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import logo from './High res brand face logo.png';
import moment from "moment";

// Add color theme constants
const COLORS = {
    isabelline: "#F4EEEA",    // Background, card backgrounds
    maastrichtBlue: "#0E1735", // Headings, important text
    shadowBlue: "#7481AF",    // Secondary elements, borders, labels
    mandarin: "#F17946",      // CTAs, highlights, errors
    shinyShamrock: "#56A27F"  // Success states, secondary actions
};

export const SuccessPage = () => {
    const { registrationID } = useParams();
    const [registrationData, setRegistrationData] = useState(null);
    const toast = useToast();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRegistration = async () => {
          setLoading(true);
          try {
            const { data, error } = await supabase
              .from("full_registration_details_with_enrollment")
              .select(
                "id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality,course_dates"
              )
              .gte("start_date", new Date().toLocaleDateString('fr-CA'))
              .eq("id", registrationID)
              .single();
    
            if (error) throw error;
    
            setRegistrationData(data);
          } catch (error) {
            console.error("Error fetching registration:", error);
            toast({
              title: "Error",
              description: "Could not load registration details.",
              status: "error",
              isClosable: true,
              position: "top",
            });
          } finally {
            setLoading(false);
          }
        };
    
        fetchRegistration();
      }, [registrationID, toast]);

    return (
        loading ? (
            <Center h="100vh" bg={COLORS.isabelline}>
                <Spinner size="xl" thickness="4px" speed="0.65s" color={COLORS.mandarin} />
            </Center>
        ) : (
            <Container 
                w={"100%"} 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                bg={COLORS.isabelline} 
                minH="100vh" 
                py={8}
            >
                <Box 
                    width="100%" 
                    bg="white" 
                    maxW={"95%"} 
                    px={10} 
                    py={10} 
                    borderRadius="lg" 
                    boxShadow="md"
                >
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        <VStack spacing={8} align="center" w="full">
                            <motion.div
                                initial={{ y: -50 }}
                                animate={{ y: 0 }}
                                transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
                            >
                                <Image
                                    src={logo}
                                    alt="Logo"
                                    boxSize="120px" 
                                    mb={4}
                                />
                            </motion.div>

                            <motion.div
                                initial={{ y: -50 }}
                                animate={{ y: 0 }}
                                transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
                            >
                                <Heading size={"3xl"} color={COLORS.maastrichtBlue}>
                                    🎉 Enrollment confirmed! 🎉
                                </Heading>
                            </motion.div>

                            <motion.div
                                initial={{ x: 100 }}
                                animate={{ x: 0 }}
                                transition={{ delay: 0.3, duration: 0.8, ease: "easeOut" }}
                            >
                                <Text fontSize="xl" color={COLORS.maastrichtBlue}>
                                    Thank you for your payment, {registrationData.participant_name}! Your enrollment for this course is confirmed.
                                </Text>
                            </motion.div>

                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
                            >
                                <Text fontSize="xl" color={COLORS.maastrichtBlue}>
                                    You will receive the signed student contract via your email <span style={{color: COLORS.mandarin, fontWeight: "bold"}}>{registrationData.participant_email}</span> soon.
                                </Text>
                                <br/>
                                <Text fontSize="xl" fontWeight="bold" color={COLORS.maastrichtBlue} textAlign="center">
                                    See you for the course!
                                </Text>
                            </motion.div>

                            {/* Course Details Section */}
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.6, duration: 1, ease: "easeOut" }}
                            >
                                <Box
                                    bg={COLORS.isabelline}
                                    border="1px solid"
                                    borderColor={COLORS.shadowBlue}
                                    borderRadius="md"
                                    p={6}
                                    boxShadow="sm"
                                    w="full"
                                    maxW="900px"
                                >
                                    <Text fontSize="2xl" fontWeight="bold" mb={4} color={COLORS.maastrichtBlue}>
                                        {registrationData?.name}
                                    </Text>
                                    <Table variant="simple" size="md" width="100%">
                                        <Thead bg={COLORS.isabelline}>
                                            <Tr>
                                                <Th textAlign="center" border="1px solid" borderColor={COLORS.shadowBlue}>Date</Th>
                                                <Th textAlign="center" border="1px solid" borderColor={COLORS.shadowBlue}>Start Time</Th>
                                                <Th textAlign="center" border="1px solid" borderColor={COLORS.shadowBlue}>End Time</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {registrationData?.course_dates.map((session, index) => (
                                                <Tr key={index}>
                                                    <Td textAlign="center" border="1px solid" borderColor={COLORS.shadowBlue}>
                                                        {moment(session.date, "DD.MM.YYYY").format("DD MMM YYYY")}
                                                    </Td>
                                                    <Td textAlign="center" border="1px solid" borderColor={COLORS.shadowBlue}>
                                                        {moment(session.start_time, "HHmm").format("h:mm A")}
                                                    </Td>
                                                    <Td textAlign="center" border="1px solid" borderColor={COLORS.shadowBlue}>
                                                        {moment(session.end_time, "HHmm").format("h:mm A")}
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                    <Text mt={4} textAlign="left" color={COLORS.maastrichtBlue}>
                                        <b>Venue:</b> Habitat Learning Centre, 1 Goldhill Plaza, #02-27, Singapore 308899. The nearest MRT is Novena.
                                    </Text>
                                    <Text mt={2} textAlign="left" color={COLORS.maastrichtBlue}>
                                        <b>Directions:</b>{" "}
                                        <a
                                            href="https://sozcode.com/goldhill-plaza-how-to-get-there"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: COLORS.mandarin, textDecoration: "underline" }}
                                        >
                                            https://sozcode.com/goldhill-plaza-how-to-get-there
                                        </a>
                                    </Text>
                                    <Text mt={4} textAlign="left" color={COLORS.maastrichtBlue}>
                                        <b>Duration:</b> 3 hours per session, except on the last day, which includes a 45-minute assessment.
                                    </Text>
                                </Box>
                            </motion.div>
                        </VStack>
                    </motion.div>
                </Box>
            </Container>
        )
    );
};

export default SuccessPage;