import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import { useNavigate } from "react-router-dom"; 
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Spinner,
  Text,
  useToast,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ListItem,
  UnorderedList,
  Image,
  Container,
  Center,
  Heading
} from "@chakra-ui/react";
import SignaturePad from "react-signature-canvas";
import { motion } from "framer-motion";
import moment from "moment";

const COLORS = {
    isabelline: "#F4EEEA",    // Background, card backgrounds
    maastrichtBlue: "#0E1735", // Headings, important text
    shadowBlue: "#7481AF",    // Secondary elements, borders, labels
    mandarin: "#F17946",      // CTAs, highlights, errors
    shinyShamrock: "#56A27F"  // Success states, secondary actions
};

export const Enrollment = () => {
  const { registrationID } = useParams();
  const [registrationData, setRegistrationData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const sigPad = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);

  console.log("Registration ID from URL:", registrationID);

  // Fetch registration data
  useEffect(() => {
    const fetchRegistration = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from("full_registration_details_with_enrollment")
          .select(
            "id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality, course_dates,code, course_objectives,course_duration,minimum_entry_requirements,prerequisites,funding_validity_period"
          )
          // .gte("start_date", new Date().toLocaleDateString('fr-CA'))
          .eq("id", registrationID)
          .single();

        if (error) throw error;

        setRegistrationData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching registration:", error);
        toast({
          title: "Error",
          description: "Could not load registration details.",
          status: "error",
          isClosable: true,
          position: "top",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchRegistration();
  }, [registrationID, toast]);

  //console.log(registrationData)

  // Save Signature and Proceed
  const saveSignatureAndProceed = async () => {
    if (isSignatureEmpty) {
      toast({
        title: "Error",
        description: "Please sign the contract before proceeding.",
        status: "error",
        isClosable: true,
        position: "top",
      });
      return;
    }
  
    setSubmitting(true);
    try {
      // Save signature
      const signature = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
      const { error: updateError } = await supabase
        .from("registration")
        .update({ signature })
        .eq("id", registrationID);
  
      if (updateError) {
        throw new Error("Failed to update signature in Supabase");
      }
  
      toast({
        title: "Signature Saved",
        description: "Your signature has been saved successfully.",
        status: "success",
        isClosable: true,
        position: "top",
      });
      
      toast({
        title: "Redirecting to Stripe payment page",
        description: "Please wait as we direct you to the payment page",
        status: "info",
        isClosable: true,
        position: "top",
      });
  
      // Call the Supabase Edge Function with detailed logging
      console.log('Sending request to Edge Function with data:', {
        product_id: registrationData.code,
        registration_id: registrationID,
        participant_email: registrationData.participant_email,
        birth_age: registrationData.birth_age,
        nationality: registrationData.nationality
      });

      const { data, error } = await supabase.functions.invoke("create-checkout-session", {
        body: {
          product_id: registrationData.code,
          registration_id: registrationID,
          participant_email: registrationData.participant_email,
          birth_age: registrationData.birth_age,
          nationality: registrationData.nationality
        },
      });

      // Log the response
      console.log('Edge Function response:', { data, error });
  
      if (error) {
        console.error('Detailed error information:', error);
        throw new Error(`Failed to create Stripe checkout session: ${error.message}`);
      }
  
      if (!data?.url) {
        console.error('Invalid response format:', data);
        throw new Error("Stripe checkout URL not found in the response.");
      }
  
      // Redirect to Stripe checkout page
      window.location.href = data.url;
  
    } catch (error) {
      console.error("Error during process:", {
        message: error.message,
        stack: error.stack,
        requestData: {
          product_id: registrationData.code,
          registration_id: registrationID,
          participant_email: registrationData.participant_email,
          birth_age: registrationData.birth_age,
          nationality: registrationData.nationality
        }
      });
  
      // Keep existing toast for user feedback
      toast({
        title: "Error during process",
        description: "- Error: Failed to create Stripe checkout session",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });

      // Add a second toast with more specific error information if available
      if (error.message.includes("Failed to update signature")) {
        toast({
          title: "Signature Error",
          description: "Could not save signature to Supabase. Please try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  // Clear Signature
  const clearSignature = () => {
    sigPad.current.clear();
    setIsSignatureEmpty(true);
  };

  if (loading) {
    return (
      <Center h="100vh" bg={COLORS.isabelline}>
        <Spinner size="xl" thickness="4px" speed="0.65s" color={COLORS.mandarin} />
      </Center>
    );
  }

  if (!registrationData) {
    return (
      <Center h="100vh" bg={COLORS.isabelline}>
        <Text>No registration data found.</Text>
      </Center>
    );
  }

  return (
    <Container 
      w={"100%"} 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      bg={COLORS.isabelline} 
      minH="100vh" 
      py={8}
    >
      <Box 
        width="100%" 
        bg="white" 
        maxW={"100%"} 
        px={10} 
        py={10} 
        borderRadius="lg" 
        boxShadow="md"
      >
        <Heading 
          size={"md"} 
          mb={10} 
          color={COLORS.maastrichtBlue} 
          borderBottom="2px solid" 
          borderColor={COLORS.mandarin} 
          pb={2} 
          width="fit-content"
        >
          Student Contract
        </Heading>

        <Alert status="info" mb={8}>
          <AlertIcon />
          Please view the terms of the Student Contract carefully before proceeding to checkout.
        </Alert>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Text fontSize="lg" as="b" color={COLORS.maastrichtBlue}>
            STUDENT CONTRACT
          </Text>
          <Divider my={4} borderColor={COLORS.maastrichtBlue} />
          <Text color={COLORS.maastrichtBlue}>
            This contract binds the Training Provider and the Student once both parties sign this Contract.
          </Text>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <VStack align="start" spacing={4} mt={6}>
            <Text fontWeight="bold" color={COLORS.maastrichtBlue}>a) Registered Name of Training Provider:</Text>
            <Text color={COLORS.maastrichtBlue}>SOZCODE PTE. LTD.</Text>
            <Text color={COLORS.maastrichtBlue}>Registration Number: 202139596G</Text>
          </VStack>
        </motion.div>
        <br />

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <VStack align="start" spacing={4}>
            <Text fontWeight="bold" color={COLORS.maastrichtBlue}>b) Student Name:</Text>
            <Text color={COLORS.maastrichtBlue}>{registrationData.participant_name}</Text>
          </VStack>
        </motion.div>

        <Divider my={6} />
        <Text fontSize="lg" fontWeight="bold" color={COLORS.maastrichtBlue}>
          1. Course Information
        </Text>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <VStack align="start" spacing={3} mt={4}>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>a) Course Title:</Text>
              <Text color={COLORS.maastrichtBlue}>{registrationData.name}</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>b) Course Objectives:</Text>
              <Text color={COLORS.maastrichtBlue}>
                  {
                      registrationData?.course_objectives
                          ?
                          registrationData.course_objectives
                          :
                          "N.A."
                  }
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>c) Course Duration:</Text>
              <Text color={COLORS.maastrichtBlue}>15 hours</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>d) Full-time or Part-time Course:</Text>
              <Text color={COLORS.maastrichtBlue}>Part-time</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>e) Attendance Requirements:</Text>
              <Text color={COLORS.maastrichtBlue}>&gt;75%</Text>
            </HStack>
            <VStack align="start" spacing={4}>
            <HStack>
                <Text fontWeight="bold" color={COLORS.maastrichtBlue}>f) Course Start Date:</Text>
                <Text color={COLORS.maastrichtBlue}>{moment(registrationData.start_date).format("DD MMM YYYY")}</Text>
            </HStack>
            <HStack>
                <Text fontWeight="bold" color={COLORS.maastrichtBlue}>g) Course End Date:</Text>
                <Text color={COLORS.maastrichtBlue}>{moment(registrationData.end_date).format("DD MMM YYYY")}</Text>
            </HStack>
            <Text fontWeight="bold" mt={4} color={COLORS.maastrichtBlue}>Course Schedule:</Text>
            <Box overflowX="auto" mt={4}>
                <Table 
                  variant="simple" 
                  size="sm" 
                  width="100%" 
                  border="1px solid" 
                  borderColor={COLORS.maastrichtBlue}
                >
                <Thead bg={COLORS.isabelline}>
                    <Tr>
                    <Th textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>Date</Th>
                    <Th textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>Start Time</Th>
                    <Th textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>End Time</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {registrationData.course_dates.map((session, index) => (
                    <Tr key={index}>
                        <Td textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>
                            {moment(session.date, "DD.MM.YYYY").format("DD MMM YYYY")}
                        </Td>
                        <Td textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>
                            {moment(session.start_time, "HHmm").format("h:mm A")}
                        </Td>
                        <Td textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>
                            {moment(session.end_time, "HHmm").format("h:mm A")}
                        </Td>
                    </Tr>
                    ))}
                </Tbody>
                </Table>
            </Box>
            </VStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>h) Modes of Training:</Text>
              <Text color={COLORS.maastrichtBlue}>In-person classroom teaching</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>i) Minimum entry requirement(s):</Text>
              <Text color={COLORS.maastrichtBlue}>
                  {
                      registrationData?.minimum_entry_requirements
                          ?
                          registrationData.minimum_entry_requirements
                          :
                          "N.A."
                  }
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>j) Prerequisites:</Text>
              <Text color={COLORS.maastrichtBlue}>
                  {
                      registrationData?.prerequisities
                          ?
                          registrationData.prerequisities
                          :
                          "N.A."
                  }
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>k) Registered Training Provider:</Text>
              <Text color={COLORS.maastrichtBlue}>Sozcode</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>l) Organisation which awards/confers the qualitifcation:</Text>
              <Text color={COLORS.maastrichtBlue}>Sozcode</Text>
            </HStack>
            <HStack>
              <Text fontWeight="bold" color={COLORS.maastrichtBlue}>m) Funding Validity Period:</Text>
              <Text color={COLORS.maastrichtBlue}>
                  {
                      registrationData?.funding_validity_period
                          ?
                          registrationData.funding_validity_period
                          :
                          "N.A."
                  }
              </Text>
            </HStack>
          </VStack>
        </motion.div>

        <Divider my={6} />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Text fontSize="lg" fontWeight="bold" color={COLORS.maastrichtBlue}>
            2. Course Fees
          </Text>
          <Box overflowX="auto" width="100%" mt={4}>
              <Table 
                variant="simple" 
                size="sm" 
                width="100%" 
                border="1px solid" 
                borderColor={COLORS.maastrichtBlue}
              >
              <Thead>
                  <Tr bg={COLORS.isabelline}>
                  <Th textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>Fees Breakdown</Th>
                  <Th textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>Total Payable ($)</Th>
                  </Tr>
              </Thead>
              <Tbody>
    <Tr>
      <Td border="1px solid" borderColor={COLORS.maastrichtBlue}>Course Fees (Before GST)</Td>
      <Td textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>{registrationData.price}</Td>
    </Tr>
    <Tr>
      <Td border="1px solid" borderColor={COLORS.maastrichtBlue}>9% GST</Td>
      <Td textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>{(0.09 * registrationData.price)}</Td>
    </Tr>
    <Tr>
      <Td border="1px solid" borderColor={COLORS.maastrichtBlue}>Less: 50% Baseline Funding</Td>
      <Td textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>
        {(registrationData.nationality === "Singapore Citizen" ||
          registrationData.nationality === "Singapore Permanent Resident" ||
          registrationData.nationality === "Long Term Visitor Pass+ (LTVP+)") 
          ? `-${(0.5 * registrationData.price)}` 
          : '-0'}
      </Td>
    </Tr>
    <Tr>
      <Td border="1px solid" borderColor={COLORS.maastrichtBlue}>Less: 20% Mid-Career Enhanced Subsidy</Td>
      <Td textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>
        {(registrationData.nationality === "Singapore Citizen" ||
          registrationData.nationality === "Singapore Permanent Resident" ||
          registrationData.nationality === "Long Term Visitor Pass+ (LTVP+)") &&
          registrationData.birth_age >= 40 
          ? `-${(0.2 * registrationData.price)}` 
          : '-0'}
      </Td>
    </Tr>
    <Tr>
      <Td border="1px solid" borderColor={COLORS.maastrichtBlue}><strong>Nett Course Fees:</strong></Td>
      <Td textAlign="center" border="1px solid" borderColor={COLORS.maastrichtBlue}>
        <strong>
          {(1.09*registrationData.price) -
            (((registrationData.nationality === "Singapore Citizen" ||
              registrationData.nationality === "Singapore Permanent Resident" ||
              registrationData.nationality === "Long Term Visitor Pass+ (LTVP+")) 
              ? (0.5 * registrationData.price) 
              : 0)- 
            ((registrationData.nationality === "Singapore Citizen" ||
              registrationData.nationality === "Singapore Permanent Resident" ||
              registrationData.nationality === "Long Term Visitor Pass+ (LTVP+)") &&
              (registrationData.birth_age >= 40 ) 
              ? (0.2 * registrationData.price) 
              : 0)}
        </strong>
      </Td>
    </Tr>
  </Tbody>
              </Table>
          </Box>
        </motion.div>

        <Divider my={6} />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Text fontSize="lg" fontWeight="bold" color={COLORS.maastrichtBlue}>
            3. Attendance
          </Text>
          <VStack align="start" spacing={3} mt={4}>
          <Text color={COLORS.maastrichtBlue}>
          Trainees attending funded courses (SSG and IBF) must take their attendance digitally via the Singpass App for both physical classroom and synchronous e-learning courses.
          </Text>
          <Text color={COLORS.maastrichtBlue}>
          If a trainee does not meet the minimum attendance requirement (if any), trainees will not be eligible to meet assessment eligibility. Otherwise, trainees are advised to request a rescheduling of training and/or assessment sessions.
          </Text>
          </VStack>
        </motion.div>
        
        <Divider my={6} />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Text fontSize="lg" fontWeight="bold" color={COLORS.maastrichtBlue}>
            4. Refund, Withdrawal and Rescheduling Policy
          </Text>
          <VStack align="start" spacing={3} mt={4}>
          <Text color={COLORS.maastrichtBlue}>
          Sozcode Pte Ltd strives to offer a fair and transparent refund policy. Please review the options below carefully:
          </Text>
          <Text color={COLORS.maastrichtBlue}>
          If a trainee does not meet the minimum attendance requirement (if any), trainees will not be eligible to meet assessment eligibility. Otherwise, trainees are advised to request a rescheduling of training and/or assessment sessions.
          </Text>
          <br />
          <Box 
            overflowX="auto" 
            maxWidth="100%" 
            margin="auto"
            px={4}
          >
            <Table 
              variant="simple" 
              size="md"
              width="100%"
              whiteSpace="normal"
              minWidth="800px"
              height="auto"
            >
                <Thead>
                <Tr>
                    <Th 
                      fontWeight="bold" 
                      textAlign="center" 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                      width="25%"
                    >
                    Request Type
                    </Th>
                    <Th 
                      fontWeight="bold" 
                      textAlign="center" 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                      width="35%"
                    >
                    Timeframe
                    </Th>
                    <Th 
                      fontWeight="bold" 
                      textAlign="center" 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                      width="40%"
                    >
                    Options for Student
                    </Th>
                </Tr>
                </Thead>
                <Tbody>
                <Tr>
                    <Td 
                      rowSpan={3} 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                      verticalAlign="top"
                    >
                    Request for refund/withdrawal
                    </Td>
                    <Td 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                    >
                    Before course confirmation email is sent
                    </Td>
                    <Td 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                    >
                    Cancel with no cancellation charges
                    </Td>
                </Tr>
                <Tr>
                    <Td 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                    >
                    At least 10 business days before the course start date
                    </Td>
                    <Td 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                    >
                    Cancel with 50% course fees still payable
                    </Td>
                </Tr>
                <Tr>
                    <Td 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                    >
                    Less than 10 business days before the course start date
                    </Td>
                    <Td 
                      color={COLORS.maastrichtBlue}
                      px={4}
                      py={4}
                    >
                    Cancel with 100% of the fees payable
                    </Td>
                </Tr>
                <Tr>
                    <Td rowSpan={2} color={COLORS.maastrichtBlue} px={4} py={4}>
                    Request for rescheduling
                    </Td>
                    <Td color={COLORS.maastrichtBlue} px={4} py={4}>
                    Recognised Absences with supporting documents:
                    <UnorderedList>
                        <ListItem>Medical</ListItem>
                        <ListItem>Reservist</ListItem>
                        <ListItem>Court appearance</ListItem>
                        <ListItem>Bereavement of immediate family members</ListItem>
                    </UnorderedList>
                    </Td>
                    <Td color={COLORS.maastrichtBlue} px={4} py={4}>
                    Re-scheduling for other course runs free-of-charge (subject to availability)
                    </Td>
                </Tr>
                <Tr>
                    <Td color={COLORS.maastrichtBlue} px={4} py={4}>
                    Rescheduling for reasons not included in Recognised Absences
                    </Td>
                    <Td color={COLORS.maastrichtBlue} px={4} py={4}>
                    Re-scheduling for other course runs at a one-time cost of $100 (subject to availability)
                    </Td>
                </Tr>
                </Tbody>
            </Table>
          </Box> 
          </VStack> 
          <VStack align="start" spacing={3} mt={4}>
          <HStack>
            <Text fontWeight="bold" color={COLORS.maastrichtBlue}>a)</Text>
            <Text color={COLORS.maastrichtBlue}>Sozcode Pte Ltd reserves the right to change or cancel the course due to unforeseen circumstances. A full refund of any course fees paid will be made to the Student in this circumstance.</Text>
          </HStack>
          <HStack>
            <Text fontWeight="bold" color={COLORS.maastrichtBlue}>b)</Text>
            <Text color={COLORS.maastrichtBlue}>A withdrawal request can be made via sending an email to courses@sozcode.com.</Text>
          </HStack>
          <HStack>
            <Text fontWeight="bold" color={COLORS.maastrichtBlue}>c)</Text>
            <Text color={COLORS.maastrichtBlue}> Refunds for credit card payments will be credited back to the original payment methods. Refunds for PayNow payment will be credited back through PaNow. All valid refunds will be credited back to the Student in an estimated 20 business days.</Text>
          </HStack>
          </VStack>
        </motion.div>

        <Divider my={6} />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Text fontSize="lg" fontWeight="bold" color={COLORS.maastrichtBlue}>
           5. Appeal and Re-assessment Process
          </Text>
          <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
            <Image 
              src="https://cdn.prod.website-files.com/61795563005b7a7fcfbcab48/674e723e6f7ad240fd55a19c_Swim%20lane%20diagram%20(Course%20operations)%20-%20Frame%202.jpg" 
              alt="Appeal and Re-assessment Process Flowchart"
              maxWidth="100%"
              objectFit="contain"
            />
          </Box>
          <VStack align="start" spacing={3} mt={4}>
          <HStack>
          <Text color={COLORS.maastrichtBlue}>A non-refundable Appeal Fee of S$60 will apply to each appeal against the Not-Yet-Competent (NYC)' status awarded. The Academic and Examination Board members' decision will be final.</Text>
          </HStack>
          <HStack>
          <Text color={COLORS.maastrichtBlue}>The Academic and Examination Board members' decision will be final.</Text>
          </HStack>
          </VStack>
        </motion.div>

        <Divider my={6} />
        <Text color={COLORS.maastrichtBlue}>The parties hereby acknowledge and agree to the terms stated in this contract.</Text>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <VStack align="start" spacing={4} mt={6}>
            <Text fontSize="lg" fontWeight="bold" color={COLORS.maastrichtBlue}>
                 Acknowledgment and Signatures
            </Text>
        <Box>
        <Text color={COLORS.maastrichtBlue}>SIGNED by Sozcode Pte Ltd.</Text>
        <Divider my={2} />
        <Box display="flex" justifyContent="left" alignItems="left" mt={4}>
            <Image 
              src="https://cdn.prod.website-files.com/61795563005b7a7fcfbcab48/674e723e8d3c5c06545cfe05_Brandon%20Signature.png" 
              alt="Sozcode Signature"
              maxWidth="30%"
              objectFit="contain"
            />
        </Box>
        <Text color={COLORS.maastrichtBlue}>__________________________</Text>
        <Text color={COLORS.maastrichtBlue}>Authorised Signatory</Text>
        <Text color={COLORS.maastrichtBlue}>Name: Brandon Ong</Text>
        <Text color={COLORS.maastrichtBlue}>Date: {new Date().toLocaleDateString('en-GB')}</Text>
        <Divider my={6} />
        </Box>
        </VStack>
    </motion.div>

    <Text color={COLORS.maastrichtBlue}>SIGNED by the Student</Text>
    <Divider my={2} />
      <Box 
        width="100%" 
        maxWidth="100%" 
        overflow="hidden"
      >
        <SignaturePad
          ref={sigPad}
          canvasProps={{
            className: "signatureCanvas",
            height: 200,
            width: "270px",
            style: { 
              border: `1px solid ${COLORS.maastrichtBlue}`, 
              borderRadius: 'md',
              maxWidth: '500px'
            }
          }}
          onEnd={() => {
            setIsSignatureEmpty(sigPad.current?.isEmpty());
          }}
        />
        <Flex 
          mt={2} 
          mb={8} 
          gap={2} 
          flexWrap="wrap" 
          maxWidth="500px"
        >
          <HStack>
          <Button 
            flex="1"
            minWidth="150px"
            size="sm" 
            variant="outline" 
            onClick={clearSignature}
            borderColor={COLORS.maastrichtBlue}
            _hover={{ borderColor: COLORS.mandarin }}
          >
            Clear Signature
          </Button>
          </HStack>
          <Box>
          <Text color={COLORS.maastrichtBlue}>__________________________</Text>
          <Text color={COLORS.maastrichtBlue}>Name of Student: {registrationData.participant_name}</Text>
          <Text color={COLORS.maastrichtBlue}>Date: {new Date().toLocaleDateString('en-GB')}</Text>
          </Box>
          <Button
            flex="1"
            minWidth="150px"
            bg={COLORS.mandarin} 
            color="white"
            size="lg"
            _hover={{
              bg: '#e06835',
            }}
            _active={{
              bg: '#d55a29',
            }}
            onClick={saveSignatureAndProceed}
            isLoading={submitting}
            isDisabled={isSignatureEmpty}
          >
            Save & Proceed to Checkout
          </Button>
        </Flex>
      </Box>
      
    </Box>
    </Container>
  );
};