import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
  Box,
  Text,
  useToast,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Spinner,
  Image,
  Heading,
  Container,
  Center
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import logo from './High res brand face logo.png'; // You'll need to add your logo file here
import moment from "moment";

// Color theme constants
const COLORS = {
    isabelline: "#F4EEEA",    // Background, card backgrounds
    maastrichtBlue: "#0E1735", // Headings, important text
    shadowBlue: "#7481AF",    // Secondary elements, borders, labels
    mandarin: "#F17946",      // CTAs, highlights, errors
    shinyShamrock: "#56A27F"  // Success states, secondary actions
};

export const SuccessRegistration = () => {
    const { registrationID } = useParams();
    const [registrationData, setRegistrationData] = useState(null);
    const toast = useToast();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRegistration = async () => {
          setLoading(true);
          try {
            const { data, error } = await supabase
              .from("full_registration_details_with_enrollment")
              .select(
                "id, participant_name, participant_email, name, start_date, end_date, price, course_run_id, registration_type, registration_stage, billing_type, birth_age, nationality,course_dates"
              )
              .gte("start_date", new Date().toLocaleDateString('fr-CA'))
              .eq("id", registrationID)
              .single();
    
            if (error) throw error;
    
            setRegistrationData(data);
          } catch (error) {
            console.error("Error fetching registration:", error);
            toast({
              title: "Error",
              description: "Could not load registration details.",
              status: "error",
              isClosable: true,
              position: "top",
            });
          } finally {
            setLoading(false);
          }
        };
    
        fetchRegistration();
      }, [registrationID, toast]);

    return (
        <Box 
            minH="100vh" 
            w="100%" 
            bg={COLORS.isabelline}
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={8}
        >
            {loading ? (
                <Center h="60vh">
                    <Spinner 
                        size="xl" 
                        thickness="4px" 
                        speed="0.65s" 
                        color={COLORS.mandarin}
                    />
                </Center>
            ) : (
                <Container maxW="container.md">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        <Box 
                            bg="white" 
                            p={8} 
                            borderRadius="lg" 
                            boxShadow="md"
                            textAlign="center"
                        >
                            <VStack spacing={8} align="center">
                                <motion.div
                                    initial={{ y: -50 }}
                                    animate={{ y: 0 }}
                                    transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
                                >
                                    <Image
                                        src={logo}
                                        alt="Sozcode Logo"
                                        boxSize="120px"
                                        objectFit="contain"
                                        mb={4}
                                    />
                                </motion.div>

                                <motion.div
                                    initial={{ y: -50 }}
                                    animate={{ y: 0 }}
                                    transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
                                >
                                    <Heading 
                                        size="2xl" 
                                        color={COLORS.maastrichtBlue}
                                        borderBottom="2px solid"
                                        borderColor={COLORS.mandarin}
                                        pb={2}
                                    >
                                        Registration received
                                    </Heading>
                                </motion.div>

                                <motion.div
                                    initial={{ x: 100 }}
                                    animate={{ x: 0 }}
                                    transition={{ delay: 0.3, duration: 0.8, ease: "easeOut" }}
                                >
                                    <Text 
                                        fontSize="xl"
                                        color={COLORS.maastrichtBlue}
                                        fontWeight="500"
                                    >
                                        Thank you for registering, {registrationData.participant_name}!
                                    </Text>
                                </motion.div>

                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.5, duration: 1, ease: "easeOut" }}
                                >
                                    <Text 
                                        fontSize="lg" 
                                        color={COLORS.shadowBlue}
                                        lineHeight="1.6"
                                    >
                                        You will receive an enrollment pending email sent to{" "}
                                        <Text 
                                            as="span" 
                                            color={COLORS.mandarin} 
                                            fontWeight="600"
                                        >
                                            {registrationData.participant_email}
                                        </Text>
                                        {" "}soon. To confirm your enrollment, please proceed to make payment using the payment link sent to this email.
                                    </Text>
                                </motion.div>

                                {/* Course Details Section */}
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.7, duration: 0.8 }}
                                >
                                    <Box 
                                        mt={6} 
                                        p={6} 
                                        bg={COLORS.isabelline} 
                                        borderRadius="md"
                                        borderWidth="1px"
                                        borderColor={COLORS.shadowBlue}
                                    >
                                        <Text 
                                            fontSize="lg" 
                                            fontWeight="600" 
                                            color={COLORS.maastrichtBlue} 
                                            mb={4}
                                        >
                                            Course Details
                                        </Text>
                                        <VStack spacing={3} align="start">
                                            <Text color={COLORS.shadowBlue}>
                                                <strong>Course:</strong> {registrationData.name}
                                            </Text>
                                            <Text color={COLORS.shadowBlue}>
                                                <strong>Start Date:</strong> {moment(registrationData.start_date).format("DD MMM YYYY")}
                                            </Text>
                                            <Text color={COLORS.shadowBlue}>
                                                <strong>End Date:</strong> {moment(registrationData.end_date).format("DD MMM YYYY")}
                                            </Text>
                                            <Text color={COLORS.shadowBlue}>
                                                <strong>Registration Type:</strong> {registrationData.registration_type}
                                            </Text>
                                        </VStack>
                                    </Box>
                                </motion.div>
                            </VStack>
                        </Box>
                    </motion.div>
                </Container>
            )}
        </Box>
    );
};

export default SuccessRegistration;