import React, {useEffect, useRef, useState} from "react";
import {useParams, useLocation, useNavigate} from "react-router-dom";
import {supabase} from "./utils/supabaseClient";
import {
    Alert,
    Box,
    Button,
    Center,
    Circle, Container, Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack, Input, Radio, RadioGroup,
    Select, Spinner,
    Stack, Tab, TabList, TabPanel, TabPanels, Tabs,
    Text,
    AlertIcon, useToast, useRadio, useRadioGroup,
    Tbody,Tr,Td,Table,Flex
} from "@chakra-ui/react";
import {HiCheck, HiOutlineDocument, HiDocumentText, HiAnnotation, HiLightBulb, HiPaperAirplane} from 'react-icons/hi'

import {CheckCircleIcon, Icon} from "@chakra-ui/icons";
import SignaturePad from "react-signature-canvas";
import { motion } from "framer-motion";
import { CheckIcon } from "@chakra-ui/icons";
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import moment from 'moment';
import NRIC from 'singapore-nric';

// Color theme constants
const COLORS = {
    isabelline: "#F4EEEA",    // Background, card backgrounds
    maastrichtBlue: "#0E1735", // Headings, important text
    shadowBlue: "#7481AF",    // Secondary elements, borders, labels
    mandarin: "#F17946",      // CTAs, highlights, errors
    shinyShamrock: "#56A27F"  // Success states, secondary actions
};

const trackGAEvent = (category, action, label) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      send_to: 'G-RM3ERYY1NG'
    });
  } else {
    console.warn('Google Analytics not loaded');
  }
};

const isDevelopment = process.env.NODE_ENV === 'development';

const devLog = (...args) => {
  if (isDevelopment) {
    console.log(...args);
  }
};

const devError = (...args) => {
  if (isDevelopment) {
    console.error(...args);
  }
};

const calculateAge = (dob) => {
    if (!dob) return null;
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

const validateNRIC = (nric) => {
    if (!nric) return false;
    const nricInstance = new NRIC(nric);
    return nricInstance.isValid && nricInstance.isCorrectFormat;
};

const validateUEN = (uen) => {
    if (!uen) return false;
    uen = uen.toUpperCase();
    
    // Business registered before 2009 (e.g., 123456789A)
    if (/^\d{9}[A-Z]$/.test(uen)) {
        return true;
    }
    
    // Local company registered before 2009 (e.g., 188000000A)
    if (/^\d{10}[A-Z]$/.test(uen)) {
        const yearPrefix = parseInt(uen.substring(0, 4));
        return yearPrefix >= 1800 && yearPrefix <= 2025;
    }
    
    // Other entities after 2009 (e.g., T09LL0001A)
    if (/^[TSR]\d{2}[A-Z]{2}\d{4}[A-Z]$/.test(uen)) {
        const year = parseInt(uen.substring(1, 3));
        return year >= 9 && year <= 25;
    }
    
    return false;
};

const Step1 = (props) => {
    const {courseRuns, formData, setFormData, allowedTab, setTabIndex, validationErrors, setValidationErrors, isTeamRegistration, setIsTeamRegistration} = props
    const [courseError, setCourseError] = useState(null);
    const [selectedCourseRunId, setSelectedCourseRunId] = useState('');
    const [formSequence, setFormSequence] = useState({
        courseSelected: false,
        nationalityFilled: false,
        ageFilled: false,
        nameFilled: false,
        emailFilled: false
    });

    // Add these new states at the top with other state declarations
    const [isSME, setIsSME] = useState(null);
    const [isUENValid, setIsUENValid] = useState(false);

    // Add these hooks to get location and navigate
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast();

    // Update form state based on URL params when the component mounts
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const courseRunId = params.get('course_run_id');
        
        if (courseRunId && courseRuns.length > 0) {  // Only proceed if we have course data
            const selectedCourse = courseRuns.find(course => course.attendance_id === courseRunId);
            if (selectedCourse) {
                setSelectedCourseRunId(courseRunId); // Set the radio selection
        setFormData((prevData)=>({
            ...prevData,
            registration_type: params.get('registration_type') || '',
                    course_run_id: courseRunId,
                    course_type_code: selectedCourse.course_type_code,
                    id: selectedCourse.id || '',
                }));
            } else {
                toast({
                    title: "Course not found",
                    description: "The selected course is not available",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    }, [location.search, courseRuns]); // Dependencies include courseRuns to re-run when data is loaded

    // Update sequence state when course is selected
    useEffect(() => {
        setFormSequence(prev => ({
            ...prev,
            courseSelected: !!formData.course_run_id
        }));
    }, [formData.course_run_id]);

    const handleChange = (name, value) => {
        if (name === "course_type_code") {
            // Reset course run when changing course type
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
                course_run_id: '',
                id: ''
            }));
            setSelectedCourseRunId('');
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));

            // Update sequence state based on field
            switch(name) {
                case "nationality":
                    setFormSequence(prev => ({
                        ...prev,
                        nationalityFilled: !!value
                    }));
                    break;
                case "birth_age":
                    setFormSequence(prev => ({
                        ...prev,
                        ageFilled: !!value
                    }));
                    break;
                case "participant_name":
                    setFormSequence(prev => ({
                        ...prev,
                        nameFilled: !!value
                    }));
                    break;
                case "participant_email":
                    setFormSequence(prev => ({
                        ...prev,
                        emailFilled: !!value
                    }));
                    break;
                default:
                    break;
            }
        }

        if (name === "course_run_id" || name === "registration_type") {
            const params = new URLSearchParams(location.search);
            params.set(name, value);
            navigate({ search: params.toString() }, { replace: true });
        }
    };

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'attendance_id',
        value: selectedCourseRunId, // Use controlled value instead of defaultValue
        onChange: (value) => {
            setSelectedCourseRunId(value);
            const selectedCourse = courseRuns.find(
                (course)=>course.attendance_id === value);
            if (selectedCourse) {
                setFormData((prevData)=>({
                    ...prevData,
                    course_run_id: value,
                    course_type_code: selectedCourse.course_type_code,
                    id: selectedCourse.id
                }));
                
                // Update URL
                const params = new URLSearchParams(location.search);
                params.set('course_run_id', value);
                navigate({ search: params.toString() }, { replace: true });
            }
        }
    });

    const group = getRootProps();

    const calculatePrice = (course) => {
        // Base price calculation
        const basePrice = course.name === "Python and GPT4 (ChatGPT) For Beginners" ? 1050 : course.price;
        
        // If not an SSG course, return base price without any subsidy
        if (!course.ssg_course) {
            return basePrice;
        }
        
        // Company-Sponsored cases
        if (formData.registration_type === "Company-Sponsored") {
            // SME case
            if (isSME === "yes" && 
                (formData.nationality === "Singapore Citizen" || formData.nationality === "Singapore Permanent Resident")) {
                return 0.3 * basePrice; // 70% subsidy
            }
            
            // Non-SME case - apply same logic as self-sponsored
            if (isSME === "no") {
                if (formData.nationality === "Singapore Citizen") {
                    return parseInt(formData.birth_age) >= 40 ? 0.3 * basePrice : 0.5 * basePrice;
                }
                if (formData.nationality === "Singapore Permanent Resident") {
                    return 0.5 * basePrice;
                }
            }
        }
        
        // Self-Sponsored cases
        if (formData.registration_type === "Self-Sponsored") {
            if (!formData.birth_age || !formData.nationality) {
                return basePrice;
            }
            
            if (formData.nationality === "Singapore Citizen") {
                return parseInt(formData.birth_age) >= 40 ? 0.3 * basePrice : 0.5 * basePrice;
            }
            if (formData.nationality === "Singapore Permanent Resident") {
                return 0.5 * basePrice;
            }
        }
        
        return basePrice;
    };

    useEffect(() => {
        trackGAEvent('Registration', 'form_started', 'initial_load');
    }, []);

    // Track when user selects a course
    const handleCourseSelection = (value) => {
        trackGAEvent('Registration', 'course_selected', value);
        // ... existing course selection logic
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (!formData.course_run_id) {
                trackGAEvent('Registration', 'form_abandoned', 'before_course_selection');
            } else if (!formData.participant_email) {
                trackGAEvent('Registration', 'form_abandoned', 'before_email_entry');
            } else if (!formData.mobile_no) {
                trackGAEvent('Registration', 'form_abandoned', 'before_phone_entry');
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formData]);

    return (
        <Box w={"100%"} 
            bg={COLORS.isabelline} 
            borderRadius="md" 
            p={6} 
            boxShadow="sm"
        >
            <FormControl>
                <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Course Type</FormLabel>
                <Select 
                    name="course_type" 
                    value={formData.course_type_code} 
                    placeholder='Select course type' 
                    bg={"white"}
                    borderColor={COLORS.shadowBlue}
                    _hover={{ borderColor: COLORS.maastrichtBlue }}
                    onChange={(e) => handleChange("course_type_code", e.target.value)}
                >
                    {[...new Map(courseRuns.map(course => [
                        course.course_type_code,
                        {
                            code: course.course_type_code,
                            name: course.name.split(' - ')[0],
                            ssg_course: course.ssg_course
                        }
                    ])).values()].map((type) => (
                        <option key={type.code} value={type.code}>
                            {type.name}{type.ssg_course ? " (SSG Subsidy Available!)" : ""}
                        </option>
                    ))}
                </Select>
            </FormControl>

            <FormControl mt={5}>
                <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Available Course Runs</FormLabel>
                {formData.course_type_code ? (
                    <HStack {...group} flexWrap={"wrap"}>
                        {courseRuns
                            .filter(course => course.course_type_code === formData.course_type_code)
                            .map((option) => {
                            const radio = getRadioProps({ value: option.attendance_id });
                            return (
                                <RadioCard
                                    key={option.attendance_id}
                                    {...radio}
                                    course_name={option.name}
                                    start_date={option.start_date}
                                    end_date={option.end_date}
                                    course_dates={option.course_dates}
                                />
                            );
                        })}
                    </HStack>
                ) : (
                    <Text color={COLORS.shadowBlue} fontSize="sm">Please select a course type to view available course runs.</Text>
                )}
            </FormControl>

            <FormControl mt={5}>
                <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Registration Type</FormLabel>
                <Select 
                    name="registration_type" 
                    value={formData.registration_type} 
                    placeholder='Select option' 
                    bg={"white"} 
                    borderColor={COLORS.shadowBlue}
                    _hover={{ borderColor: COLORS.maastrichtBlue }}
                    onChange={(e) => handleChange("registration_type", e.target.value)}>
                    <option value={"Self-Sponsored"} label={"Self-Sponsored"}></option>
                    <option value={"Company-Sponsored"} label={"Company-Sponsored"}></option>
                    {/* <option value={"HRP"} label={"HRP Registration (For Public Officers)"}></option> */}
                </Select>
            </FormControl>

            {formData.registration_type === "Company-Sponsored" && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <FormControl mt={5} isInvalid={validationErrors.sponsor_uen}>
                        <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Sponsor UEN</FormLabel>
                        <Input
                            value={formData.sponsor_uen || ''}
                            onChange={(e) => {
                                const uen = e.target.value.toUpperCase();
                                handleChange("sponsor_uen", uen);
                                
                                // Validate UEN on change
                                const isValid = validateUEN(uen);
                                setValidationErrors(prev => ({
                                    ...prev,
                                    sponsor_uen: !isValid && uen !== "" ? "Invalid UEN format" : false
                                }));
                                setIsUENValid(isValid);
                                if (isValid) {
                                    trackGAEvent('Registration', 'uen_entered', 'valid_uen');
                                }
                            }}
                            placeholder="e.g., 201139596G"
                            size="sm"
                            type="text"
                            maxLength={10}
                            bg="white"
                            borderColor={validationErrors.sponsor_uen ? "red.500" : COLORS.shadowBlue}
                            _hover={{ borderColor: COLORS.maastrichtBlue }}
                            _focus={{ borderColor: COLORS.mandarin, boxShadow: `0 0 0 1px ${COLORS.mandarin}` }}
                        />
                        {validationErrors.sponsor_uen && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                                Please enter a valid UEN
                            </Text>
                        )}
                    </FormControl>

                    {isUENValid && (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <FormControl mt={5} isInvalid={validationErrors.isSME}>
                                <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Is your company a SME?</FormLabel>
                                <RadioGroup onChange={setIsSME} value={isSME}>
                                    <HStack spacing="24px">
                                        <Radio value="yes">Yes</Radio>
                                        <Radio value="no">No</Radio>
                                    </HStack>
                                </RadioGroup>
                                <Text fontSize="sm" color={COLORS.shinyShamrock} mt={1}>
                                    (To qualify as an SME, your company must have at least 30% local shareholding. In addition, your company's annual turnover must be less than S$100 million OR have an employment size of not more than 200 workers.)
                                </Text>
                                {validationErrors.isSME && (
                                    <Text color="red.500" fontSize="sm" mt={1}>
                                        Please select if your company is a SME
                                    </Text>
                                )}
                            </FormControl>

            <FormControl mt={5}>
                                <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Team Registration</FormLabel>
                                <Text fontSize="sm" color={COLORS.shinyShamrock}>
                                    Registering for a team? We can help with the bulk registration! Email us at{' '}
                                    <Text 
                                        as="a" 
                                        href="mailto:courses@sozcode.com" 
                                        color={COLORS.mandarin}
                                        textDecoration="underline"
                                        _hover={{ color: '#e06835' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        courses@sozcode.com
                                    </Text>
                                    {' '}:D
                                </Text>
                            </FormControl>
                        </motion.div>
                    )}
                </motion.div>
            )}

            {formData.registration_type && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <FormControl mt={5}>
                        <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Nationality</FormLabel>
                        <Select 
                            name="nationality" 
                            value={formData.nationality} 
                            placeholder='Select option' 
                            bg={"white"}
                            borderColor={COLORS.shadowBlue}
                            _hover={{ borderColor: COLORS.maastrichtBlue }}
                            onChange={(e) => {
                                trackGAEvent('Registration', 'nationality_selected', e.target.value);
                                handleChange("nationality", e.target.value);
                            }}
                        >
                        <option value="Singapore Citizen">Singapore Citizen</option>
                        <option value="Singapore Permanent Resident">Singapore Permanent Resident</option>
                        <option value="Long Term Visitor Pass+ (LTVP+)">Long Term Visitor Pass+ (LTVP+)</option>
                        <option value="Non-Singapore Citizen/PR (FIN)">Non-Singapore Citizen/PR (FIN)</option>
                        <option value="Non-Singapore Resident (Foreign Passport)">Non-Singapore Resident (Foreign Passport)</option>
                </Select>
            </FormControl>
                </motion.div>
            )}

            {(formData.nationality === "Singapore Citizen" || 
              formData.nationality === "Singapore Permanent Resident") && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
            <FormControl mt={5}>
                        <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">NRIC Number</FormLabel>
                <Input
                            value={formData.nric || ''}
                    onChange={(e) => {
                                const nric = e.target.value.toUpperCase();
                                handleChange("nric", nric);
                            }}
                            onBlur={(e) => {
                                const nric = e.target.value;
                                const isValid = validateNRIC(nric);
                                setValidationErrors(prev => ({
                                    ...prev,
                                    nric: !isValid && nric !== "" ? "Invalid NRIC format or checksum" : false
                                }));
                                if (isValid) {
                                    trackGAEvent('Registration', 'nric_entered', 'valid_nric');
                                }
                            }}
                            placeholder="S9912345Z"
                            size="sm"
                            type="text"
                            maxLength={9}
                            bg="white"
                            borderColor={validationErrors.nric ? "red.500" : COLORS.shadowBlue}
                            _hover={{ borderColor: COLORS.maastrichtBlue }}
                            _focus={{ borderColor: COLORS.mandarin, boxShadow: `0 0 0 1px ${COLORS.mandarin}` }}
                        />
                        {validationErrors.nric && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                                Please enter a valid NRIC
                            </Text>
                        )}
                    </FormControl>
                </motion.div>
            )}

            {formData.nationality && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <FormControl mt={5}>
                        <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Date of Birth</FormLabel>
                        <Input
                            value={formData.displayDob || ''}
                            onChange={(e) => {
                                const inputDate = e.target.value;
                                handleChange("displayDob", inputDate);

                                // Convert to storage format YYYY-MM-DD
                                const date = new Date(inputDate);
                                if (!isNaN(date)) {
                                    const storedFormat = date.toISOString().split('T')[0];
                                    const age = calculateAge(storedFormat);
                                    handleChange("dob", storedFormat);
                                    handleChange("birth_age", age);
                                }
                            }}
                            onBlur={(e) => {
                                const inputDate = e.target.value;
                                if (!inputDate) {
                                    setValidationErrors(prev => ({...prev, dob: "Date of birth is required"}));
                                    return;
                                }
                                
                                const date = new Date(inputDate);
                                if (isNaN(date)) {
                                    setValidationErrors(prev => ({...prev, dob: "Invalid date"}));
                                    return;
                                }
                                
                                const age = calculateAge(date);
                                if (age < 0 || age > 150) {
                                    setValidationErrors(prev => ({...prev, dob: "Invalid date of birth"}));
                                    return;
                                }
                                
                                setValidationErrors(prev => ({...prev, dob: false}));
                                trackGAEvent('Registration', 'dob_entered', String(age));
                            }}
                            placeholder="Select date"
                    size="sm"
                            type="date"
                            bg="white"
                            borderColor={validationErrors.dob ? "red.500" : COLORS.shadowBlue}
                            _hover={{ borderColor: COLORS.maastrichtBlue }}
                            _focus={{ borderColor: COLORS.mandarin, boxShadow: `0 0 0 1px ${COLORS.mandarin}` }}
                        />
                        {validationErrors.dob && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                                {validationErrors.dob}
                            </Text>
                        )}
            </FormControl>
                </motion.div>
            )}

            {formData.nationality && formData.birth_age && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
            <FormControl mt={5}>
                        <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Participant Name (As per NRIC)</FormLabel>
                        <Input
                            value={formData.participant_name || ''}
                            onChange={(e) => handleChange("participant_name", e.target.value)}
                            onBlur={(e) => {
                                trackGAEvent('Registration', 'name_entered', 'name_provided');
                                handleChange("participant_name", e.target.value);
                            }}
                            size="sm"
                            type="text"
                            bg="white"
                            borderColor={COLORS.shadowBlue}
                            _hover={{ borderColor: COLORS.maastrichtBlue }}
                            _focus={{ borderColor: COLORS.mandarin, boxShadow: `0 0 0 1px ${COLORS.mandarin}` }}
                        />
            </FormControl>
                </motion.div>
            )}

            {formData.nationality && formData.birth_age && formData.participant_name && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <FormControl mt={5} isInvalid={validationErrors.email || validationErrors.invalidDomain}>
                        <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Participant Email</FormLabel>
                        <Input
                            value={formData.participant_email || ''}
                            onChange={(e) => handleChange("participant_email", e.target.value)}
                            onBlur={(e) => {
                                const email = e.target.value;
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                const hasCoDomain = email.endsWith('.co') && !email.endsWith('.com');
                                const hasInvalidDomain = /\.\w$/.test(email);
                                
                                setValidationErrors(prev => ({
                                    ...prev, 
                                    email: !emailRegex.test(email) && email !== "",
                                    emailSuggestion: hasCoDomain,
                                    invalidDomain: hasInvalidDomain
                                }));
                            }}
                            size="sm"
                            type="email"
                            bg="white"
                            borderColor={
                                validationErrors.email ? "red.500" :
                                validationErrors.emailSuggestion ? "yellow.400" :
                                validationErrors.invalidDomain ? "red.500" : 
                                COLORS.shadowBlue
                            }
                            _hover={{ borderColor: COLORS.maastrichtBlue }}
                            _focus={{ borderColor: COLORS.mandarin, boxShadow: `0 0 0 1px ${COLORS.mandarin}` }}
                        />
                        {validationErrors.email && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                                Please enter a valid email address
                            </Text>
                        )}
                        {validationErrors.emailSuggestion && (
                            <Text color="yellow.600" fontSize="sm" mt={1}>
                                Do you mean {formData.participant_email.replace(/\.co$/, '.com')}?
                            </Text>
                        )}
                        {validationErrors.invalidDomain && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                                Invalid domain - domain must be at least 2 characters
                            </Text>
                        )}
                    </FormControl>
                </motion.div>
            )}

            {formData.nationality && formData.birth_age && formData.participant_name && formData.participant_email && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <FormControl mt={5} isInvalid={validationErrors.mobile}>
                        <FormLabel mb={2} color={COLORS.shadowBlue} fontWeight="600">Mobile Number</FormLabel>
                        <Input
                            value={formData.mobile_no || ''}
                            onChange={(e) => handleChange("mobile_no", e.target.value)}
                            onBlur={(e) => {
                                const mobileNo = e.target.value;
                                try {
                                    const isValid = isValidPhoneNumber(mobileNo, 'SG');
                                    setValidationErrors(prev => ({
                                        ...prev, 
                                        mobile: !isValid && mobileNo !== ""
                                    }));
                                    
                                    if (isValid) {
                                        const phoneNumber = parsePhoneNumber(mobileNo, 'SG');
                                        const formattedNumber = phoneNumber.format('INTERNATIONAL');
                                        const numberOnly = phoneNumber.nationalNumber;
                                        
                                        setFormData(prevData => ({
                                            ...prevData,
                                            mobile_no: formattedNumber,
                                            mobile_no_raw: numberOnly
                                        }));

                                        // Track successful phone number entry
                                        trackGAEvent('Registration', 'phone_number_entered', 'valid_number');
                                } else {
                                        // Track invalid phone number entry
                                        trackGAEvent('Registration', 'phone_number_entered', 'invalid_number');
                                    }
                                } catch (error) {
                                    setValidationErrors(prev => ({...prev, mobile: true}));
                                    // Track phone number entry error
                                    trackGAEvent('Registration', 'phone_number_entered', 'error');
                                }
                            }}
                            placeholder="+65 1234 5678"
                            size="sm"
                            type="tel"
                            bg="white"
                            borderColor={validationErrors.mobile ? "red.500" : COLORS.shadowBlue}
                            _hover={{ borderColor: COLORS.maastrichtBlue }}
                            _focus={{ borderColor: COLORS.mandarin, boxShadow: `0 0 0 1px ${COLORS.mandarin}` }}
                        />
                        {validationErrors.mobile && (
                            <Text color="red.500" fontSize="sm" mt={1}>
                                Please enter a valid mobile number (e.g., +65 1234 5678)
                            </Text>
                        )}
                </FormControl>
                </motion.div>
            )}

            {formData.course_run_id && formData.nationality && formData.birth_age && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <Box mt={5} p={4} borderWidth="1px" borderRadius="lg" borderColor={COLORS.shadowBlue} bg="white">
                        {(() => {
                            const selectedCourse = courseRuns.find(course => course.attendance_id === formData.course_run_id);
                            if (!selectedCourse) return null;
                            
                            const originalPrice = selectedCourse.name === "Python and GPT4 (ChatGPT) For Beginners" 
                                ? 1050 
                                : selectedCourse.price;
                            const nettPrice = calculatePrice(selectedCourse);
                            const subsidyAmount = originalPrice - nettPrice;
                            
                            return (
                                <>
                                    {/* Only show subsidy message if it's an SSG course */}
                                    {selectedCourse.ssg_course && formData.registration_type !== "Company-Sponsored" && (
                                        <Text fontSize="sm" color={COLORS.shinyShamrock} mb={3}>
                                            {formData.nationality === "Singapore Citizen" ? 
                                                parseInt(formData.birth_age) >= 40 ?
                                                    "As a Singapore Citizen aged 40 and above, you qualify for a 70% subsidy." :
                                                    "As a Singapore Citizen, you qualify for a 50% subsidy." :
                                            formData.nationality === "Singapore Permanent Resident" ?
                                                "As a Singapore PR, you qualify for a 50% subsidy." :
                                                "Unfortunately you are not eligible for course fee subsidy T_T"}
                                        </Text>
                                    )}

                                    {/* Only show subsidy message for company-sponsored if it's an SSG course */}
                                    {selectedCourse.ssg_course && formData.registration_type === "Company-Sponsored" && 
                                     (formData.nationality === "Singapore Citizen" || formData.nationality === "Singapore Permanent Resident") && (
                                        <Text fontSize="sm" color={COLORS.shinyShamrock} mb={3}>
                                            {isSME === "yes" 
                                                ? `As a ${formData.nationality === "Singapore Citizen" ? "Singaporean" : "PR"} employee of a SME, you qualify for 70% subsidy.`
                                                : isSME === "no"
                                                    ? formData.nationality === "Singapore Citizen"
                                                        ? parseInt(formData.birth_age) >= 40
                                                            ? "As a Singapore Citizen aged 40 and above, you qualify for a 70% subsidy."
                                                            : "As a Singapore Citizen, you qualify for a 50% subsidy."
                                                        : "As a Singapore PR, you qualify for a 50% subsidy."
                                                    : "Qualifies for up to 70% subsidy at any age if your company is an SME."}
                                        </Text>
                                    )}

                                    <Heading size="xxs" mb={3} color={COLORS.maastrichtBlue}>Price Calculation ({selectedCourse.name})</Heading>
                                    <HStack justify="space-between" mb={2}>
                                        <Text color={COLORS.shadowBlue}>Original Price:</Text>
                                        <Text fontSize="12px" color={COLORS.maastrichtBlue}>SGD {originalPrice}</Text>
                                    </HStack>
                                    
                                    {/* Only show subsidy amount if it's an SSG course and there is a subsidy */}
                                    {selectedCourse.ssg_course && subsidyAmount > 0 && (
                                        <HStack justify="space-between" mb={2}>
                                            <Text color={COLORS.shadowBlue}>Subsidy:</Text>
                                            <Text fontSize="12px" color={COLORS.shinyShamrock}>SGD {subsidyAmount}</Text>
                                        </HStack>
                                    )}
                                    
                                    <Divider mb={2} borderColor={COLORS.shadowBlue} />
                                    <HStack justify="space-between">
                                        <Text fontWeight="bold" fontSize="20px" color={COLORS.maastrichtBlue}>
                                            {selectedCourse.ssg_course ? "Nett Price After Subsidy:" : "Course Fee:"}
                                        </Text>
                                        <Text fontWeight="bold" fontSize="25px" color={COLORS.mandarin}>SGD {nettPrice}</Text>
                                    </HStack>
                                </>
                            );
                        })()}
        </Box>
                </motion.div>
            )}
        </Box>
    );
};

function RadioCard(props) {
    const { getInputProps, getRadioProps } = useRadio(props)
    const {course_name, start_date, end_date, course_dates} = props
    const input = getInputProps()
    const checkbox = getRadioProps()

    return (
        <Box as='label' w={300}>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                borderColor={COLORS.shadowBlue}
                _checked={{
                    bg: COLORS.maastrichtBlue,
                    color: 'white',
                    borderColor: COLORS.maastrichtBlue,
                }}
                _hover={{
                    borderColor: COLORS.mandarin,
                }}
                _focus={{
                    boxShadow: `0 0 0 2px ${COLORS.mandarin}`,
                }}
                px={5}
                py={3}
            >
                <Text fontSize="sm" fontWeight="600" mb={4}>{course_name}</Text>
                <Text fontSize="xs">
                    Start: {moment(start_date).format("DD MMM YYYY")} ({moment(start_date).format("ddd")})
                </Text>
                <Text fontSize="xs" mb={3}>
                    End: {moment(end_date).format("DD MMM YYYY")} ({moment(end_date).format("ddd")})
                </Text>
                
                {course_dates?.length > 0 && (
                    <Box mt={2} borderTop="1px solid" borderColor={checkbox._checked ? "whiteAlpha.300" : COLORS.shadowBlue} pt={2}>
                        <Text fontSize="xs" fontWeight="600" mb={2}>Schedule:</Text>
                        <Table variant="simple" size="sm" bg="transparent">
                            <thead>
                                <tr>
                                    <th style={{fontSize: "10px", padding: "4px", textAlign: "left"}}>Date</th>
                                    <th style={{fontSize: "10px", padding: "4px", textAlign: "left"}}>Time</th>
                                </tr>
                            </thead>
                            <Tbody>
                                {course_dates?.map((session, index) => {
                                    const sessionDate = moment(session.date, "DD.MM.YYYY");
                                    return (
                                    <Tr key={index}>
                                            <Td fontSize="13px" p={1} bg="transparent" border="none">
                                                {sessionDate.format("DD MMM YYYY")} ({sessionDate.format("ddd")})
                                            </Td>
                                            <Td fontSize="13px" p={1} bg="transparent" border="none" textAlign="center">
                                                {moment(session.start_time, "HHmm").format("h:mm A")} - {moment(session.end_time, "HHmm").format("h:mm A")}
                                            </Td>
                                    </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

const Step2 = (props) => {
    const {courseRuns, course_run_id, setFormData, formData, allowedTab, setTabIndex, validationErrors, isTeamRegistration} = props
    const [isSubmitting, setIsSubmitting] = useState(false)

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const registration_type = params.get("registration_type")

    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    //const selected_course_run = courseRuns.filter((each) => each.attendance_id === course_run_id)[0]
    //const log_input_data = () => {console.log(formData)}
    const navigate = useNavigate();
    const insertRegistration = async () => {
        trackGAEvent('Registration', 'submission_attempted', 'initiated');
        setIsSubmitting(true)
        try {
            const { data, error } = await supabase
                .from('registration') 
                .insert([{
                        participant_name: formData.participant_name, 
                        participant_email: formData.participant_email, 
                        registration_type: formData.registration_type, 
                        nationality: formData.nationality, 
                    nric: formData.nric,
                    dob: formData.dob,
                        birth_age: formData.birth_age, 
                    mobile_no: formData.mobile_no_raw,
                        course_run_id: formData.id, 
                    registration_stage: "pending",
                    sponsor_uen: formData.registration_type === "Company-Sponsored" ? formData.sponsor_uen : null
                }])
                .select("id,participant_email")
                .single();

            if (error) {
                setIsSubmitting(false);
                alert("Failed to register. Please try again.");
                trackGAEvent('Registration', 'submission_failed', error.message);
            } else {
                await fetch("https://eo3f0jtagjlr5to.m.pipedream.net", {
                    method: "POST", 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        registration_id: data.id,
                        registration_type: formData.registration_type
                    }),
                });
                setIsSubmitting(false);
                trackGAEvent('Registration', 'submission_successful', 'completed');
                navigate(`/success/registration/${data.id}`);
            }
        } catch (error) {
            setIsSubmitting(false);
            trackGAEvent('Registration', 'submission_failed', 'unexpected_error');
        }
    };


    return (
        <Box>
            {registration_type === "Self-Sponsored" || registration_type === "Company-Sponsored" ? (
                <Box>
                {registration_type === "Self-Sponsored" || registration_type === "Company-Sponsored" ? (
                  <Flex justifyContent={['center', 'center', 'flex-start']} mt="10" mb="8">
                    <Button
                      isLoading={isSubmitting}
                      size="lg"
                      bg={COLORS.mandarin}
                      color="white"
                      _hover={{ bg: '#e06835' }}
                      _active={{ bg: '#d55a29' }}
                      isDisabled={
                        !formData.course_run_id ||
                        !formData.nationality ||
                        !formData.birth_age ||
                        !formData.participant_name ||
                        !formData.participant_email ||
                        !formData.mobile_no ||
                        validationErrors.email ||
                        validationErrors.mobile ||
                        validationErrors.dob ||
                        (formData.registration_type === "Company-Sponsored" && (
                            validationErrors.sponsor_uen || 
                            !formData.sponsor_uen || 
                            validationErrors.isSME || 
                            validationErrors.isTeamRegistration
                        )) ||
                        (formData.nationality === "Singapore Citizen" || 
                         formData.nationality === "Singapore Permanent Resident") && 
                        (validationErrors.nric || !formData.nric)
                      }
                      onClick={insertRegistration}
                    >
                Submit Registration
            </Button> 
                  </Flex>
                ) : null}
              </Box>
              
            ) : null}
        </Box>
    )
}

const Checkout = (props) => {
    const {sigPad, formData, setFormData, courseRuns, allowedTab, setTabIndex} = props

    return (
        <Box>
            <Spinner/>
            <Text>Please wait as we redirect you to checkout</Text>
        </Box>
    )
}

const Step3 = (props) => {
    const {sigPad, formData, setFormData, courseRuns, allowedTab, setTabIndex} = props
    const [submitting, setSubmitting] = useState(false)
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const course_run_id = params.get("course_run_id")
    const selected_course_run = courseRuns.filter((each) => each.attendance_id === course_run_id)[0]
    const toast = useToast()

    const insertRegistration = async () => {
        setSubmitting(true);
        try {
            const { data, error } = await supabase
                .from('registration')
                .insert([
                    {
                        participant_name: formData?.participant_name,
                        participant_email: formData?.participant_email,
                        billing_type: formData?.billing_type,
                        registration_type: formData?.registration_type,
                        signature: formData?.signature,
                        course_run_id: selected_course_run.id,
                        registration_stage: "pending"
                    },
                ])
                .select("id, participant_email")
                .single();

            if (error) throw error;

            return data;
        } catch (error) {
            console.error('Error inserting registration:', error);
            setSubmitting(false);
            throw error; // Re-throw error so it can be caught later
        }
    };

    const handleCheckout = async (registration) => {
        try {
            const response = await fetch('https://eovhmnu1acpextc.m.pipedream.net', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    product_id: selected_course_run.course_type_code,
                    registration_id: registration.id,
                    participant_email: registration.participant_email
                }),
            });

            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
                setSubmitting(false);
            } else {
                console.error('No URL found in response');
            }
        } catch (error) {
            console.error('Error during the checkout process:', error);
        }
    };

    const saveSignature = async () => {
        if (sigPad.current?.isEmpty()) {
            toast({
                title: "Sign the student contract before proceeding to checkout",
                status: "error",
                isClosable: true,
                position: "top",
            });
        } else {
            try {
                if (sigPad.current) {
                    const dataURL = sigPad.current.getTrimmedCanvas().toDataURL('image/png');

                    // Update formData with the signature
                    setFormData((prevData) => ({
                        ...prevData,
                        signature: dataURL,
                    }));

                    // Insert the registration and await its result
                    const registrationData = await insertRegistration();

                    if (registrationData) {
                        // Proceed to checkout with the registration data
                        await handleCheckout(registrationData);
                    }
                }
            } catch (error) {
                console.error('Error in saveSignature process:', error);
            }
        }
    };

    const clearSignature = () => {
        if (sigPad.current) {
            sigPad.current.clear();
        }
    };

    return (
        <div>
            <Alert status='info' mb={8}>
                <AlertIcon />
                Please view the terms of the Student Contract carefully before proceeding to checkout
            </Alert>
                <Text fontSize={"lg"} as={"b"}>STUDENT CONTRACT (FOR SELF-SPONSORED)</Text>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            This contract binds the Training Provider and the Student once both parties sign this Contract.
            This contract is made between:
                <br/>
                <br/>
                <ol type="a" style={{marginLeft: "20px", listStyleType: "lower-alpha"}}>
                    <li>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Registered Name of Training Provider:
                            </Text>
                            <Text>SOZCODE PTE. LTD</Text>
                            Registration Number: 202139596G<br/>
                        </HStack>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Registration Number:
                            </Text>
                            <Text>202139596G</Text>
                        </HStack>
                    </li><br/>
                    <li>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Student Name:
                            </Text>
                            <Text>{formData?.participant_name}</Text>
                        </HStack>
                        <HStack mb={1}>
                            <Text color="fg.muted" fontWeight={300} mr={3}>
                                Email:
                            </Text>
                            <Text>{formData?.participant_email}</Text>
                        </HStack>

                    </li>
                </ol>
                <br/>
                <br/>
           <strong>Course Information</strong>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            <HStack mb={1}>
                <Text color="fg.muted" fontWeight={300} mr={3}>
                    Course Title:
                </Text>
                <Text>{selected_course_run?.name}</Text>
            </HStack>
            <HStack mb={1}>
                <Text color="fg.muted" fontWeight={300} mr={3}>
                    Course Commencement Date:
                </Text>
                <Text>{moment(selected_course_run?.start_date).format("DD MMM YYYY")}</Text>
            </HStack>
            <HStack mb={1}>
                <Text color="fg.muted" fontWeight={300} mr={3}>
                    Course End Date:
                </Text>
                <Text>{moment(selected_course_run?.end_date).format("DD MMM YYYY")}</Text>
            </HStack>
            <br/>
            <br/>
                <strong>Course Fees</strong>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            <table style={{border: "1px solid black", width: "100%"}}>
                    <thead>
                    <tr>
                        <th style={{border: "1px solid black"}}>
                            <td>Fee Breakdown</td>
                        </th>
                        <th style={{border: "1px solid black"}}>
                            <td>Amount</td>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr style={{border: "1px solid black"}}>
                            <td style={{border: "1px solid black"}}>
                                Full Course Fee
                            </td>
                            <td style={{border: "1px solid black"}}>
                                SGD {selected_course_run?.price}
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: "1px solid black"}}>
                                Nett fee payable by individual
                            </td>
                            <td style={{border: "1px solid black"}}>
                                SGD {selected_course_run?.price}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <strong>Refund/Withdrawal and Rescheduling Policy</strong>
            <Divider size={"md"} colorScheme={"blackAlpha"} mb={4}/>
            <table style={{border: "1px solid black", width: "100%"}}>
                    <thead>


                    <tr>
                        <th style={{border: "1px solid black"}}>
                            <td>Request for Withdrawal</td>
                        </th>
                        <th style={{border: "1px solid black"}}>
                            <td>Options for student</td>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={{border: "1px solid black"}}>
                        <td style={{border: "1px solid black"}}>
                            Withdrawal request made before sending of course confirmation email
                        </td>
                        <td style={{border: "1px solid black"}}>
                            ·   	Cancel with no cancellation charges
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: "1px solid black"}}>
                            Withdrawal request made at least 10 working days before the course start date
                        </td>
                        <td style={{border: "1px solid black"}}>
                            ·   	Re-scheduling for other course runs (if available)<br/>
                            ·   	Send someone else in your place<br/>
                            ·   	Cancel with 100% course fees still payable<br/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border: "1px solid black"}}>
                            Withdrawal request made less than 10 working days before the course start date
                        </td>
                        <td style={{border: "1px solid black"}}>
                            ·   	Send someone else in your place<br/>
                            ·   	Cancel with 100% course fees still payable<br/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            <br/>
                <br/>

            <ol type="a" style={{marginLeft: "20px", listStyleType: "lower-alpha"}}>
                    <li>The Training Provider reserves the right to change or cancel the course due to unforeseen circumstances. A full refund of any course fees paid will be made to the Student in this circumstance.

                    </li><br/>
                    <li>A withdrawal request can be made via sending an email to courses@sozcode.com.

                    </li><br/>
                </ol>
            <br/>
            <br/>
            <FormLabel>            I hereby acknowledge and agree to the terms stated in this Contract.
            </FormLabel>
            <Box w={300}>
                <SignaturePad ref={sigPad} canvasProps={{ className: 'signatureCanvas', height: '300px', width: '400px' }} />
            </Box>
            <Box>
                <Button mt="1" mb="8" maxW={200} minW={100}  w="20%" size={"xs"} variant={"outline"} onClick={clearSignature}>
                    Clear Signature
                </Button>
            </Box>
            <Box>
                <Button  mt="4" mb="8" size={"lg"} isLoading={submitting} onClick={saveSignature}>
                    Proceed to Payment
                </Button>
            </Box>
        </div>
    )
}

export const Registration = () => {
    const [courseRuns, setCourseRuns] = useState([]);
    const [name, setName] = useState("");
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(null)
    const [loadingCourseRuns, setLoadingCourseRuns] = useState(false)
    const { courseRunId } = useParams();
    const sigPad = useRef(null);
    const [formData, setFormData] = useState({
        participant_name: '',
        participant_email: '',
        registration_type: '',
        course_run_id: '',
        signature: '',
        dob: '',
        displayDob: '',
        // ... other fields
    });
    const [isTeamRegistration, setIsTeamRegistration] = useState(null);

    const [tabIndex, setTabIndex] = useState(0)
    const [allowedTab, setAllowedTab] = useState(0)

    const handleTabsChange = (index) => {
        setTabIndex(index)
    }

    // In the Registration component, keep the validationErrors state
    const [validationErrors, setValidationErrors] = useState({
        email: false,
        mobile: false,
        emailSuggestion: false,
        invalidDomain: false,
        dob: false,
        nric: false,
        sponsor_uen: false,
        isSME: false,
        isTeamRegistration: false,
    });

    useEffect(() => {
        const steps = [
            ['course_run_id', 'registration_type'],
            ['course_run_id', 'registration_type', 'participant_name', 'participant_email'],
            ['course_run_id', 'registration_type', 'participant_name', 'participant_email', 'signature']
        ];

        let allowedTab = 0;

        for (let i = 0; i < steps.length; i++) {
            if (steps[i].every(field => formData[field])) {
                allowedTab = i + 1;
            } else {
                break;
            }
        }
        setAllowedTab(allowedTab);
    }, [formData])
    //Get supabase data
    const getAllCourseRuns = async () => {
        setLoadingCourseRuns(true)
        const { data, error } = await supabase
            .from("course_run_details")
            .select("*, ssg_course")
            .gte("start_date", new Date().toLocaleDateString('fr-CA'))
            .order("start_date")

        if (data) {
            setCourseRuns(data);
            setLoadingCourseRuns(false)
        }
        if (error) {
            console.error("Error fetching course runs:", error);
            setLoadingCourseRuns(false)
        }
    };



    useEffect(() => {
        getAllCourseRuns();
    }, []);

    useEffect(() => {
        document.title = "Registration";
        
        // Cleanup function to reset the title when component unmounts
        return () => {
            document.title = "Learn @ Sozcode";
        };
    }, []);

    return (
            <Container w={"100%"} display="flex" justifyContent="center" alignItems="center" bg={COLORS.isabelline} minH="100vh" py={8}>
                {
                    loadingCourseRuns
                        ?
                        <Center h="60vh">
                            <Spinner size="xl" thickness="4px" speed="0.65s" color={COLORS.mandarin} />
                        </Center>
                        :
                        <Stack spacing="4" width="100%" bg={"white"} maxW={"95%"} px={10} py={10} borderRadius="lg" boxShadow="md">
                            <Heading size={"md"} mb={10} color={COLORS.maastrichtBlue} borderBottom="2px solid" borderColor={COLORS.mandarin} pb={2} width="fit-content">
                                Course Registration
                            </Heading>
                            <Step1 
                                courseRuns={courseRuns} 
                                formData={formData} 
                                setFormData={setFormData} 
                                allowedTab={allowedTab} 
                                setTabIndex={setTabIndex}
                                validationErrors={validationErrors}
                                setValidationErrors={setValidationErrors}
                                isTeamRegistration={isTeamRegistration}
                                setIsTeamRegistration={setIsTeamRegistration}
                            />
                            <Step2 
                                formData={formData} 
                                setFormData={setFormData} 
                                allowedTab={allowedTab} 
                                setTabIndex={setTabIndex}
                                validationErrors={validationErrors}
                                isTeamRegistration={isTeamRegistration}
                            />
                            {/*<Tabs index={tabIndex} onChange={handleTabsChange}>*/}
                            {/*    <TabList>*/}
                            {/*        <Tab isDisabled={allowedTab <= 0}>1. Registration</Tab>*/}
                            {/*        <Tab isDisabled={allowedTab < 1}>2. Participant Details</Tab>*/}
                            {/*        <Tab isDisabled={allowedTab < 2}>3. Student Contract</Tab>*/}
                            {/*        <Tab isDisabled={allowedTab < 3}>4. Checkout</Tab>*/}
                            {/*    </TabList>*/}
                            {/*    <TabPanels>*/}
                            {/*        <TabPanel>*/}
                            {/*            <Step1 courseRuns={courseRuns} formData={formData} setFormData={setFormData} allowedTab={allowedTab} setTabIndex={setTabIndex}/>*/}
                            {/*        </TabPanel>*/}
                            {/*        <TabPanel>*/}
                            {/*            <Step2 formData={formData} setFormData={setFormData} allowedTab={allowedTab} setTabIndex={setTabIndex}/>*/}
                            {/*        </TabPanel>*/}
                            {/*        <TabPanel>*/}
                            {/*            <Step3 sigPad={sigPad} formData={formData} setFormData={setFormData} courseRuns={courseRuns} allowedTab={allowedTab} setTabIndex={setTabIndex}/>*/}
                            {/*        </TabPanel>*/}
                            {/*        <TabPanel>*/}
                            {/*            <Checkout formData={formData} setFormData={setFormData} courseRuns={courseRuns} allowedTab={allowedTab} setTabIndex={setTabIndex}/>*/}
                            {/*        </TabPanel>*/}
                            {/*    </TabPanels>*/}
                            {/*</Tabs>*/}
                        </Stack>
                }
            </Container>
    );
};
