import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import {
    Box,
    Center,
    Heading,
    Stack,
    Text,
    Card,
    CardBody,
    CardHeader,
    useColorModeValue,
    Container,
    Flex,
    Badge
} from "@chakra-ui/react";
import moment from "moment";
import { Spinner } from '@chakra-ui/react'
import { motion } from "framer-motion";

// Color theme constants
const COLORS = {
    isabelline: "#F4EEEA",    // Background, card backgrounds
    maastrichtBlue: "#0E1735", // Headings, important text
    shadowBlue: "#7481AF",    // Secondary elements, borders, labels
    mandarin: "#F17946",      // CTAs, highlights, errors
    shinyShamrock: "#56A27F"  // Success states, secondary actions
};

export const AttendanceSelection = () => {
    const [coursesToday, setCoursesToday] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // Fetch courses happening today
    const fetchCoursesToday = async () => {
        setIsLoading(true);
        const today = new Date().toISOString().split("T")[0];
        const { data, error } = await supabase
            .from("course_run_details")
            .select("id, name, start_date, end_date,attendance_id,venue")
            .gte("end_date", today)
            //.lte("end_date", today)
            .order("start_date", { ascending: true })
            .limit(5); // Fetch up to 5 courses for presentation

        if (error) {
            console.error("Error fetching courses:", error);
            setCoursesToday([]);
        } else {
            console.log(data);
            setCoursesToday(data);
            if (data.length === 1) {
                // If only one course, redirect to its attendance page
                navigate(`/attendance/${data[0].attendance_id}`);
            }
        }
        setIsLoading(false);
    };

    // Fetch courses when component mounts
    useEffect(() => {
        fetchCoursesToday();
    }, []);

    const isCourseToday = (startDate, endDate) => {
        const today = moment().startOf('day');
        const courseStart = moment(startDate).startOf('day');
        const courseEnd = moment(endDate).startOf('day');
        return today.isBetween(courseStart, courseEnd, null, '[]') || 
               today.isSame(courseStart) || 
               today.isSame(courseEnd);
    };

    const daysUntilCourse = (startDate) => {
        const today = moment().startOf('day');
        const courseStart = moment(startDate).startOf('day');
        return courseStart.diff(today, 'days');
    };

    const cardBg = "white";

    return (
        <Container 
            maxW="container.lg" 
            py={8}
            minH="100vh"
            bg={COLORS.isabelline}
        >
            <Box 
                bg="white" 
                borderRadius="2xl" 
                boxShadow="sm" 
                p={8}
                mb={8}
            >
                <Heading 
                    size="md" 
                    color={COLORS.maastrichtBlue} 
                    mb={8}
                    fontWeight="semibold"
                    letterSpacing="tight"
                    textAlign="center"
                    borderBottom="2px solid"
                    borderColor={COLORS.mandarin}
                    pb={2}
                    width="fit-content"
                    mx="auto"
                >
                    Upcoming Courses
                </Heading>

                {isLoading ? (
                    <Center minH="200px">
                        <Spinner size="xl" thickness="4px" speed="0.65s" color={COLORS.mandarin} />
                    </Center>
                ) : (
                    <Flex direction="column" gap={6}>
                        {coursesToday.length > 0 ? (
                            coursesToday.map((course, index) => {
                                const isToday = isCourseToday(course.start_date, course.end_date);
                                const daysUntil = daysUntilCourse(course.start_date);

                                return (
                                    <motion.div
                                        key={course.id}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.3, delay: index * 0.1 }}
                                    >
                                        <Card
                                            bg={cardBg}
                                            border="1px solid"
                                            borderColor={COLORS.shadowBlue}
                                            borderRadius="2xl"
                                            boxShadow="sm"
                                            transition="all 0.2s ease-in-out"
                                            _hover={{
                                                transform: "translateY(-4px)",
                                                boxShadow: "lg"
                                            }}
                                            cursor="pointer"
                                            onClick={() => navigate(`/attendance/${course.attendance_id}`)}
                                            minH="120px"
                                        >
                                            <CardBody>
                                                <Flex 
                                                    direction="column" 
                                                    gap={2}
                                                >
                                                    {/* Course Type Badge */}
                                                    <Badge
                                                        bg={COLORS.isabelline}
                                                        color={COLORS.maastrichtBlue}
                                                        borderRadius="full"
                                                        px={3}
                                                        py={1}
                                                        w="fit-content"
                                                        fontSize="sm"
                                                        fontWeight="medium"
                                                    >
                                                        Course
                                                    </Badge>

                                                    {/* Course Name */}
                                                    <Heading 
                                                        size="sm" 
                                                        color={COLORS.maastrichtBlue}
                                                        fontWeight="semibold"
                                                    >
                                                        {course.name}
                                                    </Heading>

                                                    <Text 
                                                        size="xs" 
                                                        color={COLORS.maastrichtBlue}
                                                    >
                                                        Venue: {course.venue.split(/,|-/)[0]}
                                                    </Text>

                                                    {/* Dates - Stacked for mobile */}
                                                    <Box>
                                                        <Text color={COLORS.shadowBlue} fontSize="md">
                                                            <strong>Start:</strong> {moment(course.start_date).format("DD MMM YYYY")}
                                                        </Text>
                                                        <Text color={COLORS.shadowBlue} fontSize="md">
                                                            <strong>End:</strong> {moment(course.end_date).format("DD MMM YYYY")}
                                                        </Text>
                                                    </Box>

                                                    {/* Today/Days Badge */}
                                                    <Badge
                                                        bg={isToday ? COLORS.mandarin : COLORS.isabelline}
                                                        color={isToday ? "white" : COLORS.maastrichtBlue}
                                                        borderRadius="full"
                                                        px={3}
                                                        py={1}
                                                        w="fit-content"
                                                        fontSize="sm"
                                                        fontWeight="medium"
                                                        border={!isToday ? `1px solid ${COLORS.shadowBlue}` : "none"}
                                                    >
                                                        {isToday ? "Today!" : `In ${daysUntil} ${daysUntil === 1 ? 'day' : 'days'}`}
                                                    </Badge>
                                                </Flex>
                                            </CardBody>
                                        </Card>
                                    </motion.div>
                                );
                            })
                        ) : (
                            <Box textAlign="center" py={10}>
                                <Text color={COLORS.shadowBlue}>
                                    No ing courses scheduled
                                </Text>
                            </Box>
                        )}
                    </Flex>
                )}
            </Box>
        </Container>
    );
};