import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {supabase} from "./utils/supabaseClient";
import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Select,
    Stack,
    Text,
    Badge
} from "@chakra-ui/react";
import {CheckCircleIcon} from "@chakra-ui/icons";
import SignaturePad from "react-signature-canvas";
import moment from "moment";
import { COLORS } from "./styles/colors";

export const Attendance = () => {
    const [attendees, setAttendees] = useState([]);
    const [name, setName] = useState("");
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(null)
    const [clickCount, setClickCount] = useState(0);
    const { courseRunId } = useParams();
    const sigPad = useRef(null);
    const [signedAttendees, setSignedAttendees] = useState([]);

    const getAllAttendees = async () => {
        const { data, error } = await supabase
            .from("registration")
            .select("participant_name, id, course_run_details!inner(*)")
            .eq("course_run_details.attendance_id", courseRunId);

        if (data) {
            console.log(data);
            setAttendees(data);
        }
        if (error) {
            console.log(error);
        }
    };

    const getSignedAttendees = async () => {
        const today = moment().format('YYYY-MM-DD');
        
        const { data, error } = await supabase
            .from('attendance')
            .select('registration_id, created_at')
            .eq('attendance_id', courseRunId)
            .gte('created_at', today + 'T00:00:00')
            .lte('created_at', today + 'T23:59:59');

        if (data) {
            setSignedAttendees(data.map(item => item.registration_id));
        }
        if (error) {
            console.log(error);
        }
    };

    const insertAttendance = async(signature) => {
        setSubmitting(true)
        const { data, error } = await supabase
            .from('attendance')
            .insert([
                { attendance_id: courseRunId, registration_id: name, signature: signature },
            ])
            .select()
            .single()
        if (data) {
            setSubmitting(false)
            setSuccess(data)
            getSignedAttendees();
            console.log(data)
        }

        if (error) {
            console.log(error)
            setSubmitting(false)
        }
    }

    useEffect(() => {
        getAllAttendees();
        getSignedAttendees();
    }, [courseRunId]);

    const clearSignature = () => {
        sigPad.current.clear();
    };

    const saveSignature = () => {
        if (!name) {
            alert("Please select a name before submitting.");
            return; // Prevent submission if no name is selected
        }
        if (sigPad.current?.isEmpty()) {
            alert("Please sign before submitting."); // Optional: Replace with a toast notification
            return; // Prevent submission if the signature pad is empty
        }
        if (sigPad.current) {
            const dataURL = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
            console.log(name)
            console.log(dataURL);
            insertAttendance(dataURL)
            // You can now send this dataURL to your server or use it as needed
        }
    };

    const isCourseToday = (startDate, endDate) => {
        const today = moment().startOf('day');
        const courseStart = moment(startDate).startOf('day');
        const courseEnd = moment(endDate).startOf('day');
        return today.isBetween(courseStart, courseEnd, null, '[]') || 
               today.isSame(courseStart) || 
               today.isSame(courseEnd);
    };

    const courseToday = attendees[0]?.course_run_details ? 
        isCourseToday(attendees[0].course_run_details.start_date, attendees[0].course_run_details.end_date) : 
        false;

    const handleBackdoorClick = () => {
        setClickCount(prev => prev + 1);
    };

    const isBackdoorActive = clickCount >= 5;

    return (
        success ? (
            <Container 
                maxW="container.lg" 
                py={8}
                minH="100vh"
                bg={COLORS.isabelline}
            >
                <Box 
                    bg="white" 
                    borderRadius="2xl" 
                    boxShadow="sm" 
                    p={8}
                    mb={8}
                >
                    <Stack spacing="4" width="100%" textAlign="center">
                        <Heading size="md">Submission success</Heading>
                        <Center>
                            <CheckCircleIcon boxSize={15} color="green.500" />
                        </Center>
                        <Text>
                            Attendance received from {attendees.find(each => each.id === success.registration_id)?.participant_name} on {moment(success.created_at).format('DD MMM YYYY')} at {moment(success.created_at).format('HH:mm')}
                        </Text>
                    </Stack>
                </Box>
            </Container>
        ) : (
            <Container 
                maxW="container.lg" 
                py={8}
                minH="100vh"
                bg={COLORS.isabelline}
            >
                <Box 
                    bg="white" 
                    borderRadius="2xl" 
                    boxShadow="sm" 
                    p={8}
                    mb={8}
                >
                    <Stack spacing="6">
                        <Heading 
                            size="sm"
                            color={COLORS.maastrichtBlue} 
                            mb={4}
                            fontWeight="semibold"
                            borderBottom="2px solid"
                            borderColor={COLORS.mandarin}
                            pb={2}
                            width="fit-content"
                        >
                            Attendance For {attendees[0]?.course_run_details?.name}
                        </Heading>

                        <Text color={COLORS.shadowBlue} fontSize="15px" fontWeight="bold">
                            Course Dates: {moment(attendees[0]?.course_run_details?.start_date).format("DD MMM YYYY")} - {moment(attendees[0]?.course_run_details?.end_date).format("DD MMM YYYY")}
                        </Text>

                        <Badge
                            bg="transparent"
                            color={COLORS.maastrichtBlue}
                            border="1px solid"
                            borderRadius="full"
                            px={3}
                            py={1}
                            w="fit-content"
                            fontSize="10px"
                            fontWeight="medium"
                        >
                            Today's Date: {moment().format("DD MMM YYYY")}
                        </Badge>

                        <FormControl>
                            <FormLabel color={COLORS.maastrichtBlue} fontWeight="bold">Name</FormLabel>
                            <Box>
                                <Select 
                                    value={name} 
                                    placeholder="Select your name" 
                                    bg="white"
                                    borderColor={COLORS.shadowBlue}
                                    _hover={{ borderColor: COLORS.maastrichtBlue }}
                                    onChange={(e) => setName(e.target.value)}
                                >
                                    {attendees.map(each => (
                                        <option 
                                            key={each.id} 
                                            value={each.id}
                                            style={{
                                                color: signedAttendees.includes(each.id) ? COLORS.shinyShamrock : COLORS.maastrichtBlue,
                                                backgroundColor: signedAttendees.includes(each.id) ? '#f0f9f4' : 'white',
                                                fontWeight: signedAttendees.includes(each.id) ? '500' : 'normal'
                                            }}
                                        >
                                            {signedAttendees.includes(each.id) ? `(Signed today) ` : ''}{each.participant_name}
                                        </option>
                                    ))}
                                </Select>
                                <Box 
                                    mt={2} 
                                    p={3} 
                                    bg="white" 
                                    borderRadius="md" 
                                    border="1px solid"
                                    borderColor={COLORS.shadowBlue}
                                >
                                    <Text 
                                        fontSize="sm" 
                                        color={COLORS.shadowBlue}
                                        textAlign="center"
                                    >
                                        <Text as="span" color={COLORS.shinyShamrock} fontWeight="bold">
                                            {signedAttendees.length}
                                        </Text>
                                        <Text as="span" color={COLORS.maastrichtBlue}>
                                            {' '}out of{' '}
                                        </Text>
                                        <Text as="span" color={COLORS.mandarin} fontWeight="bold">
                                            {attendees.length}
                                        </Text>
                                        <Text as="span" color={COLORS.maastrichtBlue}>
                                            {' '}participants have signed in today
                                        </Text>
                                    </Text>
                                </Box>
                            </Box>
                        </FormControl>

                        {name && (
                            <>
                                <Box 
                                    bg={COLORS.isabelline}
                                    borderRadius="lg"
                                    p={4}
                                    textAlign="center"
                                    border="1px solid"
                                    borderColor={COLORS.shadowBlue}
                                >
                                    <Text 
                                        color={COLORS.shinyShamrock}
                                        fontSize="md"
                                        fontWeight="bold"
                                        mb={1}
                                    >
                                        Hello {attendees.find(each => each.id === name)?.participant_name}! 
                                    </Text>
                                    <Text
                                        color={COLORS.shadowBlue}
                                        fontSize="sm"
                                        fontWeight="400"
                                    >
                                        Glad to have you today! 😊
                                        <br />
                                        Please sign below <Text as="span" color={COLORS.mandarin}>⬇️</Text>
                                    </Text>
                                </Box>

                                <FormControl>
                                    <FormLabel color={COLORS.maastrichtBlue} fontWeight="bold">Signature</FormLabel>
                                    <Box 
                                        border="1px solid"
                                        borderColor={COLORS.shadowBlue}
                                        borderRadius="md"
                                        overflow="hidden"
                                        width="100%"
                                        height="200px"
                                    >
                                        <SignaturePad 
                                            ref={sigPad} 
                                            canvasProps={{ 
                                                className: 'signatureCanvas',
                                                width: '900px',
                                                height: '200px'
                                            }} 
                                        />
                                    </Box>
                                </FormControl>

                                <Button 
                                    mt={2}
                                    size="sm"
                                    variant="outline"
                                    onClick={clearSignature}
                                    alignSelf="flex-start"
                                >
                                    Clear Signature
                                </Button>
                            </>
                        )}

                        {!courseToday && (
                            <Text 
                                color={COLORS.mandarin} 
                                fontSize="sm" 
                                textAlign="center" 
                                mb={4}
                                fontStyle="italic"
                                onClick={handleBackdoorClick}
                                cursor="pointer"
                            >
                                "Hold your horses! 🐎 This course isn't happening today. 
                                Come back when it's showtime!"
                                {isBackdoorActive && (
                                    <Text fontSize="xs" color={COLORS.shinyShamrock} mt={1}>
                                        Backdoor activated! You can now submit.
                                    </Text>
                                )}
                            </Text>
                        )}

                        {name && (
                            <Button 
                                isLoading={submitting} 
                                onClick={saveSignature}
                                bg={courseToday || isBackdoorActive ? COLORS.mandarin : "gray.300"}
                                color={courseToday || isBackdoorActive ? "white" : "gray.500"}
                                size="lg"
                                px={12}
                                _hover={(courseToday || isBackdoorActive) ? { bg: COLORS.mandarin, opacity: 0.9 } : {}}
                                cursor={(courseToday || isBackdoorActive) ? "pointer" : "not-allowed"}
                                isDisabled={!courseToday && !isBackdoorActive}
                                alignSelf="center"
                            >
                                Submit
                            </Button>
                        )}
                    </Stack>
                </Box>
            </Container>
        )
    );
};
