import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { supabase } from "./utils/supabaseClient";
import { useAuth } from "./providers/AuthProvider";
import {
    Box,
    Button,
    Heading,
    Stack,
    Text,
    Card,
    HStack,
    Badge,
    Divider,
    VStack,
    Container,
    Center,
    Spinner
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { COLORS } from "./styles/colors";

export const AssessmentForCourses = () => {
    const { user } = useAuth();
    const [groupedCourses, setGroupedCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    const getAllEnrolledCourses = async () => {
        try {
            const { data, error } = await supabase
                .from("full_assessment_details")
                .select("*")
                .eq("user_id", user.id);

            if (error) throw error;

            const grouped = data.reduce((acc, item) => {
                const courseName = item.name;
                if (!acc[courseName]) {
                    acc[courseName] = {
                        name: courseName,
                        start_date: item.start_date,
                        end_date: item.end_date,
                        assessments: [],
                    };
                }
                acc[courseName].assessments.push({
                    assessment_name: item.assessment_name,
                    deadline: item.assessment_deadline
                        ? item.assessment_deadline.split("T")[0]
                        : "No Deadline",
                    submitted_on: item.submitted_on,
                    id: item.id,
                    assessment_id: item.assessment_id
                });
                return acc;
            }, {});

            setGroupedCourses(Object.values(grouped));
        } catch (error) {
            console.error("Error fetching assessments:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllEnrolledCourses();
    }, []);

    if (loading) {
        return (
            <Center minH="100vh" bg={COLORS.isabelline}>
                <Spinner size="xl" color={COLORS.mandarin} />
            </Center>
        );
    }

    return (
        <Box minH="100vh" bg={COLORS.isabelline} py={8}>
            <Container maxW="container.lg">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <Box
                        bg="white"
                        p={8}
                        borderRadius="lg"
                        boxShadow="md"
                    >
                        <Heading
                            size="lg"
                            mb={6}
                            color={COLORS.maastrichtBlue}
                            borderBottom="2px solid"
                            borderColor={COLORS.mandarin}
                            pb={2}
                        >
                            Assessments For Your Enrolled Courses
                        </Heading>

                        {groupedCourses.length > 0 ? (
                            <VStack spacing={6} align="stretch">
                                {groupedCourses.map((course) => (
                                    <motion.div
                                        key={course.name}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <Card
                                            p={6}
                                            borderRadius="md"
                                            borderWidth="1px"
                                            borderColor={COLORS.shadowBlue}
                                            _hover={{ shadow: "lg" }}
                                        >
                                            <Heading size="md" mb={4} color={COLORS.maastrichtBlue}>
                                                {course.name}
                                            </Heading>

                                            <VStack spacing={3} align="stretch" mb={4}>
                                                <HStack>
                                                    <Text w={150} color={COLORS.shadowBlue} fontWeight="500">
                                                        Start Date:
                                                    </Text>
                                                    <Text>{course.start_date}</Text>
                                                </HStack>
                                                <HStack>
                                                    <Text w={150} color={COLORS.shadowBlue} fontWeight="500">
                                                        End Date:
                                                    </Text>
                                                    <Text>{course.end_date}</Text>
                                                </HStack>
                                            </VStack>

                                            <VStack spacing={4} align="stretch">
                                                {course.assessments.map((assessment, index) => (
                                                    <motion.div
                                                        key={index}
                                                        initial={{ opacity: 0, y: 20 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        transition={{ duration: 0.3, delay: index * 0.1 }}
                                                    >
                                                        <Box>
                                                            <Badge
                                                                mb={2}
                                                                colorScheme={assessment.submitted_on ? "green" : "red"}
                                                            >
                                                                {assessment.submitted_on ? "Submitted" : "Not Complete"}
                                                            </Badge>
                                                            <Text fontWeight="medium" color={COLORS.maastrichtBlue}>
                                                                {assessment.assessment_name}
                                                            </Text>
                                                            <HStack mt={1}>
                                                                <Text color={COLORS.shadowBlue} fontSize="sm">
                                                                    Deadline: {assessment.deadline}
                                                                </Text>
                                                            </HStack>
                                                            <Link to={`/assessment/${assessment.assessment_id}`}>
                                                                <Button
                                                                    variant="link"
                                                                    mt={2}
                                                                    color={COLORS.mandarin}
                                                                    _hover={{ color: '#e06835' }}
                                                                >
                                                                    {assessment.submitted_on ? "View submission" : "Start submission"}
                                                                </Button>
                                                            </Link>
                                                            {index !== course.assessments.length - 1 && (
                                                                <Divider mt={3} borderColor={COLORS.shadowBlue} />
                                                            )}
                                                        </Box>
                                                    </motion.div>
                                                ))}
                                            </VStack>
                                        </Card>
                                    </motion.div>
                                ))}
                            </VStack>
                        ) : (
                            <Text color={COLORS.shadowBlue} textAlign="center">
                                No assessments found for your enrolled courses.
                            </Text>
                        )}
                    </Box>
                </motion.div>
            </Container>
        </Box>
    );
};